import { createSlice } from '@reduxjs/toolkit';

/**
 * AdvIndicators Slice
 *
 * exports reducer ***AdvIndicators.reducer***
 *
 * ----
 *
 * @alias AdvIndicators
 *
 * @module AdvIndicators
 * @category . Redux store
 * */

export const AdvIndicatorsSlice = createSlice({
    name: 'AdvIndicators',
    initialState: {
        advIndicators: [],
        selectedAdvIndicators:
            localStorage.getItem('sAI')
                ? JSON.parse(localStorage.getItem('sAI'))
                : [],
        expanded:localStorage.getItem("eAI") ? JSON.parse(localStorage.getItem("eAI")) : [''],
        scrolled: null,
        scrollTop: localStorage.getItem("tstAI") ? JSON.parse(localStorage.getItem("tstAI")) : 0,
        additional: {
            dimentions: true,
            variables: false
        },
        infoOU: [],
        infoUP: [],
        infoWSK: [],
        simpleTree: false,
        selectedAdvPrzekroje:
            localStorage.getItem('sAP')
                ? JSON.parse(localStorage.getItem('sAP'))
                : [],
        defaultAdvPrzekroje: [],
    },
    reducers: {
        setAdvIndicators: (state, action) => {
            state.advIndicators = action.payload;
        },
        setSelectedAdvIndicators: (state, action) => {
            localStorage.setItem('sAI', JSON.stringify(action.payload))
            state.selectedAdvIndicators = action.payload;
        },
        setExpanded: (state, action) => {
            localStorage.setItem("eAI", JSON.stringify(action.payload));
            state.expanded = action.payload
        },
        setScrolled: (state, action) => {
            state.scrolled = action.payload
        },
        setScrollTop: (state, action) => {
            localStorage.setItem("tstAI", action.payload);
            state.scrollTop = action.payload;
        },
        setAdditional: (state, action) => {
            state.additional = action.payload
        },
        setInfoOU: (state, action) => {
            state.infoOU = action.payload
        },
        setInfoUP: (state, action) => {
            state.infoUP = action.payload
        },
        setInfoWSK: (state, action) => {
            state.infoWSK = action.payload
        },
        setSimpleTree: (state, action) => {
            state.simpleTree = action.payload
        },
        setSelectedAdvPrzekroje: (state, action) => {
            localStorage.setItem('sAP', JSON.stringify(action.payload))
            state.selectedAdvPrzekroje = action.payload;
        },
        setDefaultAdvPrzekroje: (state, action) => {
            state.defaultAdvPrzekroje = action.payload
        },
    },
});

export const {
    setAdvIndicators,
    setSelectedAdvIndicators,
    setExpanded,
    setScrolled,
    setAdditional,
    setInfoOU,
    setInfoUP,
    setInfoWSK,
    setSimpleTree,
    setSelectedAdvPrzekroje,
    setDefaultAdvPrzekroje,
    setScrollTop
} = AdvIndicatorsSlice.actions;

export const selectSelectedAdvIndicators = state => state.AdvIndicators.selectedAdvIndicators;
export const selectAdvIndicators = state => state.AdvIndicators.advIndicators;
export const selectExpanded = state => state.AdvIndicators.expanded;
export const selectScrolled = state => state.AdvIndicators.scrolled;
export const selectAdditional = state => state.AdvIndicators.additional;
export const selectInfoOU = state => state.AdvIndicators.infoOU;
export const selectInfoUP = state => state.AdvIndicators.infoUP;
export const selectInfoWSK = state => state.AdvIndicators.infoWSK;
export const selectSimpleTree = state => state.AdvIndicators.simpleTree;
export const selectAdvPrzekroje = state => state.AdvIndicators.selectedAdvPrzekroje;
export const selectDefaultAdvPrzekroje = state => state.AdvIndicators.defaultAdvPrzekroje;
export const getScrollTop = state => state.AdvIndicators.scrollTop;

export default AdvIndicatorsSlice.reducer;
