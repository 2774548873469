import {createSlice} from '@reduxjs/toolkit';

/**
 * Indicators Slice
 *
 * exports reducer ***IndicatorsSlice.reducer***
 *
 * ----
 *
 * @alias IndicatorsSlice
 *
 * @example
 *
 * import {useSelector, useDispatch} from 'react-redux';
 * import {selectedIndicator, setIndicators, setSelectedIndicator} from 'path/to/appStore/Indicators';
 *
 * ...
 *
 * const MyComponent = () => {
 *     const dispatch = useDispatch();
 *
 *     //select
 *     const selectedIndicator = useSelector(selectedIndicator);
 *     // - or -
 *     const selectedIndicator = useSelector(state => state.indicators.selectedIndicator)
 *
 *     //set indicators
 *     dispatch(setIndicators(ArrayOfObjects));
 *     dispatch(setSelectedIndicator(Object));
 *
 *     ...
 *
 *     return(
 *         <pre>{JSON.stringify(selectedIndicator, null, 4)}</pre>
 *     )
 *
 * }
 * export default MyComponent
 *
 * @param {Object} initialState
 * @param {Object[]} initialState.indicators *array of objects*
 * @param {Object} initialState.selectedIndicator *Selected (current object)*
 *
 * @param {} selectors
 * @param {function} selectors.selectedIndicator returns object (current)
 *
 * @param {Object} reducers
 * @param {function} reducers.setIndicators Redux action to set `indicators` (from ***`${BASE_URL}/Data/GetWyszukajWskazniki/${nazwa}`***)
 * @param {function} reducers.setSelectedIndicator Redux action to set `selectedIndicator`
 *
 * @module IndicatorsSlice
 * @category . Redux store
 * */

export const IndicatorsSlice = createSlice({
    name: 'indicators',
    initialState: {
        indicators: [],
        selectedIndicator: null
    },
    reducers: {
        setIndicators: (state, action) => {
            state.indicators = action.payload;
        },
        setSelectedIndicator: (state, action) => {
            state.selectedIndicator = action.payload;
        },
    },
});

export const {setSelectedIndicator, setIndicators} = IndicatorsSlice.actions;

export const selectedIndicator = state => state.indicators.selectedIndicator;

export default IndicatorsSlice.reducer;
