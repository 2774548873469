import { createSlice } from "@reduxjs/toolkit";

/**
 * AdvancedTable Slice
 *
 * exports reducer ***AdvancedTableSlice.reducer***
 *
 * ----
 *
 * @alias AdvancedTableSlice
 *
 * export default MyComponent
 *
 * @module AdvancedTableSlice
 * @category . Redux store
 *
 * */

export const AdvancedTableSlice = createSlice({
    name: "AdvancedTable",
    initialState: {
        settings: localStorage.getItem("ATS")
            ? JSON.parse(localStorage.getItem("ATS"))
            : {
                  layout: {
                      selectListItems: [
                          {
                              id: "JT",
                              content: "Jednostka terytorialna",
                              field: "JT",
                              width: 600,
                              shortWidth: 240,
                              longWidth: 240,
                          },
                      ],
                      rowsTitlesItems: [
                          { id: "W", content: "Wskaznik", field: "WSK", width: 800, shortWidth: 400, longWidth: 800 },
                          {
                              id: "D",
                              content: "Wymiar",
                              field: "DIM",
                              width: 800,
                              shortWidth: 300,
                              longWidth: 600,
                              hidden: true,
                          },
                          {
                              id: "P",
                              content: "Pozycja wymiaru",
                              field: "POS",
                              width: 800,
                              shortWidth: 300,
                              longWidth: 600,
                              hidden: true,
                          },
                      ],
                      columnsItems: [
                          { id: "Year", content: "Lata", field: "Year", width: 100, shortWidth: 150, longWidth: 100 },
                      ],
                  },
                  additional: {
                      hideEmptyCols: false,
                      hideEmptyRows: false,
                      showTERYT: false,
                      showKTS: false,
                      showHierarchyJT: false,
                      showHierarchyWSK: false,
                  },
              },
        defaultSettings: {
            layout: {
                selectListItems: [
                    {
                        id: "JT",
                        content: "Jednostka terytorialna",
                        field: "JT",
                        width: 600,
                        shortWidth: 240,
                        longWidth: 240,
                    },
                ],
                rowsTitlesItems: [
                    { id: "W", content: "Wskaznik", field: "WSK", width: 800, shortWidth: 400, longWidth: 800 },
                    {
                        id: "D",
                        content: "Wymiar",
                        field: "DIM",
                        width: 800,
                        shortWidth: 300,
                        longWidth: 600,
                        hidden: true,
                    },
                    {
                        id: "P",
                        content: "Pozycja wymiaru",
                        field: "POS",
                        width: 800,
                        shortWidth: 300,
                        longWidth: 600,
                        hidden: true,
                    },
                ],
                columnsItems: [
                    { id: "Year", content: "Lata", field: "Year", width: 100, shortWidth: 150, longWidth: 100 },
                ],
            },
            additional: {
                hideEmptyCols: false,
                hideEmptyRows: false,
                showTERYT: false,
                showKTS: false,
                showHierarchyJT: false,
                showHierarchyWSK: false,
            },
        },
        statAndOper: localStorage.getItem("ATSaO")
            ? JSON.parse(localStorage.getItem("ATSaO"))
            : {
                  enabled: true,
                  filters: {
                      filterType: "none",
                      user: {
                          id_daty: "",
                          value: "",
                          moreOrLess: "more",
                          open: false,
                      },
                      amount: {
                          id_daty: "",
                          minOrMax: "min",
                          value: "",
                          open: false,
                      },
                      percentage: {
                          id_daty: "",
                          minOrMax: "min",
                          value: "",
                          open: false,
                      },
                  },
                  operations: {
                      average: { selected: false, value: "col" },
                      mediana: { selected: false, value: "col" },
                      dynamic: { selected: false, value: "lastYear", id_daty: "" },
                  },
              },
        defaultStatAndOper: {
            enabled: true,
            filters: {
                filterType: "none",
                user: {
                    id_daty: "",
                    value: "",
                    moreOrLess: "more",
                    open: false,
                },
                amount: {
                    id_daty: "",
                    minOrMax: "min",
                    value: "",
                    open: false,
                },
                percentage: {
                    id_daty: "",
                    minOrMax: "min",
                    value: "",
                    open: false,
                },
            },
            operations: {
                average: { selected: false, value: "col" },
                mediana: { selected: false, value: "col" },
                dynamic: { selected: false, value: "lastYear", id_daty: "" },
            },
        },
        dataForJT: localStorage.getItem("d4JT") ? JSON.parse(localStorage.getItem("d4JT")) : {},
        loadingDataForJT: false,
        fullTableData: localStorage.getItem("FTD") ? JSON.parse(localStorage.getItem("FTD")) : [],
        currentTableData: localStorage.getItem("cTD") ? JSON.parse(localStorage.getItem("cTD")) : [],
        currentRows: null,
        fullTableDataRE: [],
        loadingFullTableData: false,
        selectedJT: localStorage.getItem("sJT") ? JSON.parse(localStorage.getItem("sJT")) : {},
        selectedYear: localStorage.getItem("sY") ? JSON.parse(localStorage.getItem("sY")) : {},
        selectedWSK: localStorage.getItem("sWSK") ? JSON.parse(localStorage.getItem("sWSK")) : {},
        showPivot: localStorage.getItem("sP")
            ? localStorage.getItem("sP").toString() === "false"
                ? false
                : true
            : false,
        counters: localStorage.getItem("cntrs")
            ? JSON.parse(localStorage.getItem("cntrs"))
            : { TOTAL_LIMIT: 50000, limited: false, COLS_LIMIT: 100 },
        showLimted: localStorage.getItem("lmtd") ? JSON.parse(localStorage.getItem("lmtd")) : true,
        minMax: { min: null, max: null },
    },
    reducers: {
        setAdvancedTableSettings: (state, action) => {
            localStorage.setItem("ATS", JSON.stringify(action.payload));
            state.settings = action.payload;
        },
        setAdvancedTableStatAndOper: (state, action) => {
            localStorage.setItem("ATSaO", JSON.stringify(action.payload));
            state.statAndOper = action.payload;
        },
        setDataForJT: (state, action) => {
            localStorage.setItem("d4JT", JSON.stringify(action.payload));
            state.dataForJT = action.payload;
        },
        setLoadingDataForJT: (state, action) => {
            state.loadingDataForJT = action.payload;
        },
        setFullTableData: (state, action) => {
            // localStorage.setItem('FTD', JSON.stringify(action.payload))
            state.fullTableData = action.payload;
        },
        setCurrentTableData: (state, action) => {
            // localStorage.setItem('FTD', JSON.stringify(action.payload))
            state.currentTableData = action.payload;
        },
        mergeFullTableData: (state, action) => {
            state.fullTableDataRE = action.payload;
        },
        setLoadingFullTableData: (state, action) => {
            state.loadingFullTableData = action.payload;
        },
        setSelectedJT: (state, action) => {
            localStorage.setItem("sJT", JSON.stringify(action.payload));
            state.selectedJT = action.payload;
        },
        setSelectedYear: (state, action) => {
            localStorage.setItem("sY", JSON.stringify(action.payload));
            state.selectedYear = action.payload;
        },
        setSelectedWSK: (state, action) => {
            localStorage.setItem("sWSK", JSON.stringify(action.payload));
            state.selectedWSK = action.payload;
        },
        setShowPivot: (state, action) => {
            localStorage.setItem("sP", action.payload);
            state.showPivot = action.payload;
        },
        setCurrentRows: (state, action) => {
            state.currentRows = action.payload;
        },
        setCounters: (state, action) => {
            localStorage.setItem("cntrs", JSON.stringify(action.payload));
            state.counters = action.payload;
        },
        setShowLimited: (state, action) => {
            localStorage.setItem("lmtd", JSON.stringify(action.payload));
            state.showLimted = action.payload;
        },
        setMinMax: (state, action) => {
            // localStorage.setItem("lmtd", JSON.stringify(action.payload));
            state.minMax = action.payload;
        },
    },
});

export const {
    setAdvancedTableSettings,
    setAdvancedTableStatAndOper,
    setDataForJT,
    setLoadingDataForJT,
    setSelectedJT,
    setSelectedYear,
    setSelectedWSK,
    setFullTableData,
    mergeFullTableData,
    setLoadingFullTableData,
    setShowPivot,
    setCurrentTableData,
    setCurrentRows,
    setCounters,
    setShowLimited,
    setMinMax,
} = AdvancedTableSlice.actions;

export const selectAdvancedTableSettings = (state) => state.AdvancedTable.settings;
export const selectAdvancedTableDefaultSettings = (state) => state.AdvancedTable.defaultSettings;
export const selectAdvancedTableStatAndOper = (state) => state.AdvancedTable.statAndOper;
export const selectAdvancedTableDefaultStatAndOper = (state) => state.AdvancedTable.defaultStatAndOper;
export const selectDataForJT = (state) => state.AdvancedTable.dataForJT;
export const selectLoadingDataForJT = (state) => state.AdvancedTable.loadingDataForJT;
export const selectFullTableData = (state) => state.AdvancedTable.fullTableData;
export const selectCurrentTableData = (state) => state.AdvancedTable.currentTableData;
export const selectLoadingFullTableData = (state) => state.AdvancedTable.loadingFullTableData;
export const selectSelectedJT = (state) => state.AdvancedTable.selectedJT;
export const selectSelectedYear = (state) => state.AdvancedTable.selectedYear;
export const selectSelectedWSK = (state) => state.AdvancedTable.selectedWSK;
export const selectShowPivot = (state) => state.AdvancedTable.showPivot;
export const selectCurrentRows = (state) => state.AdvancedTable.currentRows;
export const getCounters = (state) => state.AdvancedTable.counters;
export const getShowLimited = (state) => state.AdvancedTable.showLimted;
export const getMinMax = (state) => state.AdvancedTable.minMax;

export default AdvancedTableSlice.reducer;
