import { openDB } from "idb";

const dbPromise = openDB("smup", 1, {
    upgrade(db) {
        db.createObjectStore("store");
    },
});

export async function get(key) {
    return (await dbPromise).get("store", key);
}
export async function set(key, val) {
    return (await dbPromise).put("store", val, key);
}
export async function del(key) {
    return (await dbPromise).delete("store", key);
}
export async function clear() {
    return (await dbPromise).clear("store");
}
export async function keys() {
    return (await dbPromise).getAllKeys("store");
}
