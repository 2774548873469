import jwt_decode from "jwt-decode";
import moment from "moment";
import axios from "axios";
import { BASE_URL } from "../API/apiConfig";

import { MIN_JWT_EXPIRE_DIFF } from "../API/apiConfig";
import { clear } from "./_idb";

export const processJWT = (store) => (next) => (action) => {
    const authToken = store.getState().AdvancedCommon.authToken;
    const authMessage = store.getState().AdvancedCommon.authMessage;

    let result = next(action);

    if (authToken) {
        const decoded = jwt_decode(authToken);
        const willExpire = moment(decoded.exp * 1000);
        const isNow = moment();
        const difference = willExpire.diff(isNow, "miliseconds");

        if (difference <= 0) {
            /** Expired. Logout. */

            /** log out */
            result = next({
                payload: null,
                type: "AdvancedCommon/setAuthToken",
            });

            result = next({
                payload: null,
                type: "AdvancedCommon/setAuthMessage",
            });

            /** Clear repo */
            result = next({ type: "AdvRepozytorium/setCharts", payload: [] });
            result = next({ type: "AdvRepozytorium/setGroups", payload: [] });
            result = next({ type: "AdvRepozytorium/setMaps", payload: [] });
            result = next({ type: "AdvRepozytorium/setReports", payload: [] });
            result = next({ type: "AdvRepozytorium/setTables", payload: [] });
        } else if (difference >= MIN_JWT_EXPIRE_DIFF) {
            /** Actual. Do nothing. (default 604190000) */
            // console.log(willExpire.diff(isNow, "miliseconds"), ': actual. Do nothing')

            if (authMessage) {
                result = next({
                    payload: null,
                    type: "AdvancedCommon/setAuthMessage",
                });
            }
            return result;
        } else {
            /** Once per 10 min */
            // console.log(willExpire.diff(isNow, "miliseconds"), ': renew ',)
            renewToken({ store, next });
        }
    }
    return result;
};

const renewToken = async ({ store, next }) => {
    const state = store.getState().AdvancedCommon;
    const apiURL = `${BASE_URL}User/PostNowyToken`;
    let newToken = null;

    if (state.authRememberMe && state.authToken) {
        newToken = await axios
            .post(
                apiURL,
                {
                    token: state.authToken,
                },
                {
                    headers: {
                        Authorization: `Bearer ${state.authToken}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                console.log(err, err.response);
            })
            .finally(() => {});

        next({
            payload: newToken,
            type: "AdvancedCommon/setAuthToken",
        });

        // return result
    }
};
