import { createSlice } from "@reduxjs/toolkit";

/**
 * AdvancedCharts Slice
 *
 * exports reducer ***AdvancedChartsSlice.reducer***
 *
 * ----
 *
 * @alias AdvancedChartsSlice
 *
 * export default MyComponent
 *
 * @module AdvancedChartsSlice
 * @category . Redux store
 * */

export const AdvancedChartsSlice = createSlice({
    name: "AdvancedCharts",
    initialState: {
        colorOfSeries: "",
        colorOfHighlight: "#228b22",
        colorLine: "#000080",
        colorBar: "#93ccea",
        legend4bars: true,
        legend4lines: true,
        labels: false,

        bar: localStorage.getItem("isBars")
            ? localStorage.getItem("isBars").toString() === "false"
                ? false
                : true
            : true,
        line: localStorage.getItem("isLines")
            ? localStorage.getItem("isLines").toString() === "false"
                ? false
                : true
            : false,
        tableView: {
            bar: null,
            line: null,
        },
        barChartName: localStorage.getItem("bcTitle") ? localStorage.getItem("bcTitle") : "",
        lineChartName: localStorage.getItem("lcTitle") ? localStorage.getItem("lcTitle") : "",
        selectedWSK: localStorage.getItem("sBCWSK") ? JSON.parse(localStorage.getItem("sBCWSK")) : [],
        selectedJT: localStorage.getItem("sBCJT") ? JSON.parse(localStorage.getItem("sBCJT")) : null,
        selectedYear:
            localStorage.getItem("sBCY") &&
            localStorage.getItem("sBCY") !== undefined &&
            localStorage.getItem("sBCY") !== "undefined"
                ? JSON.parse(localStorage.getItem("sBCY"))
                : null,
        selectedLinesWSK: localStorage.getItem("sLCWSK") ? JSON.parse(localStorage.getItem("sLCWSK")) : null,
        selectedLinesJT: localStorage.getItem("sLCJT") ? JSON.parse(localStorage.getItem("sLCJT")) : [],
        selectedLinesYear: null,
        barDatasets: null,
        yearsRange: localStorage.getItem("sLCYR") ? JSON.parse(localStorage.getItem("sLCYR")) : {},
        statAndOper: localStorage.getItem("ACSaO")
            ? JSON.parse(localStorage.getItem("ACSaO"))
            : {
                  enabled: true,
                  filters: {
                      filterType: "none",
                      user: {
                          id_daty: "",
                          value: "",
                          moreOrLess: "more",
                          open: false,
                      },
                      amount: {
                          id_daty: "",
                          minOrMax: "min",
                          value: "",
                          open: false,
                      },
                      percentage: {
                          id_daty: "",
                          minOrMax: "min",
                          value: "",
                          open: false,
                      },
                  },
                  operations: {
                      average: { selected: false, value: "col" },
                      mediana: { selected: false, value: "col" },
                      user: { selected: false, value: null, name: null },
                  },
              },
        defaultStatAndOper: {
            enabled: true,
            filters: {
                filterType: "none",
                user: {
                    id_daty: "",
                    value: "",
                    moreOrLess: "more",
                    open: false,
                },
                amount: {
                    id_daty: "",
                    minOrMax: "min",
                    value: "",
                    open: false,
                },
                percentage: {
                    id_daty: "",
                    minOrMax: "min",
                    value: "",
                    open: false,
                },
            },
            operations: {
                average: { selected: false, value: "col" },
                mediana: { selected: false, value: "col" },
                user: { selected: false, value: null, name: null },
            },
        },
        barSettings: localStorage.getItem("ACbS")
            ? JSON.parse(localStorage.getItem("ACbS"))
            : {
                  colors: {
                      serie: {
                          data: null,
                          isDefault: true,
                      },
                      highlight: "#FFFF99",
                      highlightedBars: [],
                  },
                  additional: {
                      shortNamesJT: true,
                      showLabels: false,
                      showLegend: true,
                  },
              },
        barDefaultSettings: localStorage.getItem("ACbdS")
            ? JSON.parse(localStorage.getItem("ACbdS"))
            : {
                  colors: {
                      serie: {
                          data: null,
                          isDefault: true,
                      },
                      highlight: "#FFFF99",
                      highlightedBars: [],
                  },
                  additional: {
                      shortNamesJT: true,
                      showLabels: false,
                      showLegend: true,
                  },
              },
        lineSettings: localStorage.getItem("AClS")
            ? JSON.parse(localStorage.getItem("AClS"))
            : {
                  colors: {
                      serie: {
                          data: null,
                          isDefault: true,
                      },
                  },
                  additional: {
                      showLegend: true,
                  },
              },
        lineDefaltSettings: localStorage.getItem("ACldS")
            ? JSON.parse(localStorage.getItem("ACldS"))
            : {
                  colors: {
                      serie: {
                          data: null,
                          isDefault: true,
                      },
                  },
                  additional: {
                      showLegend: true,
                  },
              },
        barChartSort: localStorage.getItem("AbCs") ? localStorage.getItem("AbCs") : "90",
        dataURLs: {
            bar: {
                png: "",
                jpg: "",
            },
            line: {
                png: "",
                jpg: "",
            },
        },
    },
    reducers: {
        setColorOfSeries: (state, action) => {
            state.colorOfSeries = action.payload;
        },
        setColorOfHighlight: (state, action) => {
            state.colorOfHighlight = action.payload;
        },
        setChart: (state, action) => {
            localStorage.setItem("isBars", action.payload.bar);
            state.bar = action.payload.bar;
            localStorage.setItem("isLines", action.payload.line);
            state.line = action.payload.line;
        },
        setColorOfLine: (state, action) => {
            state.colorLine = action.payload;
        },
        setLegend4Bars: (state, action) => {
            state.legend4bars = action.payload;
        },
        setLegend4Lines: (state, action) => {
            state.legend4lines = action.payload;
        },
        setLabels: (state, action) => {
            state.labels = action.payload;
        },
        setColorOfBar: (state, action) => {
            state.colorBar = action.payload;
        },
        setTableView: (state, action) => {
            state.tableView = action.payload;
        },
        setBarChartName: (state, action) => {
            localStorage.setItem("bcTitle", action.payload);
            state.barChartName = action.payload;
        },
        setLineChartName: (state, action) => {
            localStorage.setItem("lcTitle", action.payload);
            state.lineChartName = action.payload;
        },
        setSelectedWSK: (state, action) => {
            localStorage.setItem("sBCWSK", JSON.stringify(action.payload));
            state.selectedWSK = action.payload;
        },
        setSelectedJT: (state, action) => {
            localStorage.setItem("sBCJT", JSON.stringify(action.payload));
            state.selectedJT = action.payload;
        },
        setSelectedYear: (state, action) => {
            localStorage.setItem("sBCY", JSON.stringify(action.payload));
            state.selectedYear = action.payload;
        },
        setSelectedLinesWSK: (state, action) => {
            localStorage.setItem("sLCWSK", JSON.stringify(action.payload));
            state.selectedLinesWSK = action.payload;
        },
        setSelectedLinesJT: (state, action) => {
            localStorage.setItem("sLCJT", JSON.stringify(action.payload));
            state.selectedLinesJT = action.payload;
        },
        setSelectedLinesYear: (state, action) => {
            state.selectedLinesYear = action.payload;
        },
        setBarDatasets: (state, action) => {
            state.barDatasets = action.payload;
        },
        setYearsRange: (state, action) => {
            localStorage.setItem("sLCYR", JSON.stringify(action.payload));
            state.yearsRange = action.payload;
        },
        setChartsStatAndOper: (state, action) => {
            localStorage.setItem("ACSaO", JSON.stringify(action.payload));
            state.statAndOper = action.payload;
        },
        setChartsBarSettings: (state, action) => {
            localStorage.setItem("ACbS", JSON.stringify(action.payload));
            state.barSettings = action.payload;
        },
        setChartsBarDefaultSettings: (state, action) => {
            localStorage.setItem("ACbdS", JSON.stringify(action.payload));
            state.barDefaultSettings = action.payload;
        },
        setChartsLineSettings: (state, action) => {
            localStorage.setItem("AClS", JSON.stringify(action.payload));
            state.lineSettings = action.payload;
        },
        setChartsLineDefaultSettings: (state, action) => {
            localStorage.setItem("ACldS", JSON.stringify(action.payload));
            state.lineDefaltSettings = action.payload;
        },
        setBarChartSort: (state, action) => {
            localStorage.setItem("AbCs", action.payload);
            state.barChartSort = action.payload;
        },
        setDataURLs: (state, action) => {
            state.dataURLs = action.payload;
        },
    },
});

export const {
    setColorOfSeries,
    setColorOfHighlight,
    setChart,
    setColorOfLine,
    setLegend4Bars,
    setLegend4Lines,
    setLabels,
    setColorOfBar,
    setTableView,
    setBarChartName,
    setLineChartName,
    setSelectedWSK,
    setSelectedJT,
    setSelectedYear,
    setSelectedLinesWSK,
    setSelectedLinesJT,
    setSelectedLinesYear,
    setBarDatasets,
    setYearsRange,
    setChartsStatAndOper,
    setChartsBarSettings,
    setChartsBarDefaultSettings,
    setChartsLineSettings,
    setChartsLineDefaultSettings,
    setBarChartSort,
    setDataURLs,
} = AdvancedChartsSlice.actions;

export const colorOfSeries = (state) => state.AdvancedCharts.colorOfSeries;
export const colorOfHighlight = (state) => state.AdvancedCharts.colorOfHighlight;
export const colorLine = (state) => state.AdvancedCharts.colorLine;
export const legend4bars = (state) => state.AdvancedCharts.legend4bars;
export const legend4lines = (state) => state.AdvancedCharts.legend4lines;
export const labels = (state) => state.AdvancedCharts.labels;

export const bar = (state) => state.AdvancedCharts.bar;
export const line = (state) => state.AdvancedCharts.line;
export const selectTableView = (state) => state.AdvancedCharts.tableView;

export const selectBarChartName = (state) => state.AdvancedCharts.barChartName;
export const selectLineChartName = (state) => state.AdvancedCharts.lineChartName;

export const selectSelectedWSK = (state) => state.AdvancedCharts.selectedWSK;
export const selectSelectedJT = (state) => state.AdvancedCharts.selectedJT;
export const selectSelectedYear = (state) => state.AdvancedCharts.selectedYear;

export const selectSelectedLinesWSK = (state) => state.AdvancedCharts.selectedLinesWSK;
export const selectSelectedLinesJT = (state) => state.AdvancedCharts.selectedLinesJT;
export const selectSelectedLinesYear = (state) => state.AdvancedCharts.selectedLinesYear;

export const selectBarDatasets = (state) => state.AdvancedCharts.barDatasets;
export const selectYearsRange = (state) => state.AdvancedCharts.yearsRange;

export const selectChartsStatAndOper = (state) => state.AdvancedCharts.statAndOper;
export const selectChartsDefaultStatAndOper = (state) => state.AdvancedCharts.defaultStatAndOper;

export const selectBarSetting = (state) => state.AdvancedCharts.barSettings;
export const selectBarDefaultSetting = (state) => state.AdvancedCharts.barDefaultSettings;

export const selectLineSetting = (state) => state.AdvancedCharts.lineSettings;
export const selectLineDefaultSetting = (state) => state.AdvancedCharts.lineDefaltSettings;

export const selectBarChartSort = (state) => state.AdvancedCharts.barChartSort;

export const selectDataURLs = (state) => state.AdvancedCharts.dataURLs;

export default AdvancedChartsSlice.reducer;
