import { createSlice, dispatch } from "@reduxjs/toolkit";
import { get, set } from "./_idb";

export const AdvReportSlice = createSlice({
    name: "AdvReport",
    initialState: {
        // options: localStorage.getItem("ARO")
        //     ? JSON.parse(localStorage.getItem("ARO"))
        //     : {
        //           isSaved: true,
        //           landscape: false,
        //           size: "a4",
        //           reportType: "html",
        //           reportTitle: "",
        //           items: [],
        //           wskIdsForMeta: [],
        //           wskMetaNames: [],
        //       },
        options: null,
        showPreviewHTML: false,
        isHiddenPreloaded: false,
        isLoadingElements: {},
    },
    reducers: {
        setReportOptions: (state, action) => {
            // localStorage.setItem('ARO', JSON.stringify(action.payload))
            set("ARO", JSON.stringify(action.payload));
            state.options = action.payload;
        },
        setShowPreviewHTML: (state, action) => {
            state.showPreviewHTML = action.payload;
        },
        setIsHiddenPreloaded: (state, action) => {
            state.isHiddenPreloaded = action.payload;
        },
        setIsLoadingElements: (state, action) => {
            state.isLoadingElements = action.payload;
        },
    },
});

export const fetchReportFromIndexDB = () => (dispatch, getState) => {
    // console.log("%c  fetchReportFromIndexDB             ", "background:#F00");
    get("ARO").then((val) => {
        val = val
            ? JSON.parse(val)
            : {
                  isSaved: true,
                  landscape: false,
                  size: "a4",
                  reportType: "html",
                  reportTitle: "",
                  items: [],
                  wskIdsForMeta: [],
                  wskMetaNames: [],
              };
        dispatch(setReportOptions(val));
    });
};

export const { setReportOptions, setShowPreviewHTML, setIsHiddenPreloaded, setIsLoadingElements } =
    AdvReportSlice.actions;

export const selectReportOptions = (state) => state.AdvReport.options;
export const selectShowPreviewHTML = (state) => state.AdvReport.showPreviewHTML;
export const selectIsHiddenPreloaded = (state) => state.AdvReport.isHiddenPreloaded;
export const selectIsLoadingElements = (state) => state.AdvReport.isLoadingElements;

export default AdvReportSlice.reducer;
