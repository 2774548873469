import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
// import * as serviceWorker from "./serviceWorker";
import store from "./appStore/_store";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import App from "./App";

const matomoInstance = createInstance({
    urlBase: "https://stats.stat.gov.pl",
    // srcUrl: "./js/matomo.js",
    siteId:
        process?.env?.REACT_APP_MATOMO_IDSITE && !isNaN(Number(process?.env?.REACT_APP_MATOMO_IDSITE))
            ? Number(process?.env?.REACT_APP_MATOMO_IDSITE)
            : 1,
    disabled: false,
    heartBeat: {
        active: true,
        seconds: 10,
    },
    linkTracking: false,
    configurations: {
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: "POST",
    },
});

/** Disable console in 'production'*/
if (process.env.NODE_ENV === "production") {
    console.assert = () => {};
    console.count = () => {};
    console.debug = () => {};
    console.dir = () => {};
    console.dirxml = () => {};
    console.error = () => {};
    console.info = () => {};
    console.log = () => {};
    console.table = () => {};
    console.trace = () => {};
    console.warn = () => {};
}
if (process.env.NODE_ENV === "development") {
    console.error = (props) => {
        return props === "Error in setSecureCookie: You cannot use `Secure` on http." ? "" : props;
    };
}

ReactDOM.render(
    <React.Fragment>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <MatomoProvider value={matomoInstance}>
                    <App />
                </MatomoProvider>
            </ThemeProvider>
        </Provider>
    </React.Fragment>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.register();
// serviceWorker.unregister();
