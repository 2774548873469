import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import { initReactI18next } from "react-i18next";

const fallbackLng = ["pl"];
const availableLanguages = ["en", "pl"];
const detectionOptions = {
    order: ["path", "localStorage"],
    lookupQuerystring: "lang",
    lookupLocalStorage: "i18nextLng",
};

i18n.use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng,
        debug: false,
        whitelist: availableLanguages,
        // lng: localStorage.getItem("lang") || "pl",
        interpolation: {
            escapeValue: false,
        },
        detection: detectionOptions,
        //  nsSeparator: false,
        // keySeparator: false,
        // saveMissing: true,
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
    });

export default i18n;
