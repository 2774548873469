import { createSlice } from "@reduxjs/toolkit";

export const BasicCommonSlice = createSlice({
    name: "BasicCommon",
    initialState: {
        shareMessage: false,
    },
    reducers: {
        setShareMessage: (state, action) => {
            state.shareMessage = action.payload;
        },
    },
});

export const { setShareMessage } = BasicCommonSlice.actions;

export const getShareMessage = (state) => state.BasicCommon.shareMessage;

export default BasicCommonSlice.reducer;
