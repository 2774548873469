import { createSlice } from '@reduxjs/toolkit';

/**
 * AdvMap Slice
 *
 * exports reducer ***AdvMap.reducer***
 *
 * ----
 *
 * @alias AdvMap
 *
 * @module AdvMap
 * @category . Redux store
 * */

export const AdvMapSlice = createSlice({
    name: 'AdvMap',
    initialState: {
        generalOptions: localStorage.getItem('am_GO')
            ? JSON.parse(localStorage.getItem('am_GO'))
            : {
                mapTitle: "",
                selectedJt: '',
                selectedYear: "",
                selectedWSK: [],
            },
        kartogramOptions: localStorage.getItem('am_KO')
            ? JSON.parse(localStorage.getItem('am_KO'))
            : {
                display: true,
                wskObject: null
            },
        kartodiagramOptions: localStorage.getItem('am_KdO')
            ? JSON.parse(localStorage.getItem('am_KdO'))
            : {
                display: false,
                wskObject: null
            },
        mapDimensions: localStorage.getItem('am_MD')
            ? JSON.parse(localStorage.getItem('am_MD'))
            : {
                zoom: 0.1,
                center: null
            },
    },
    reducers: {
        setGeneralOptions: (state, action) => {
            localStorage.setItem('am_GO', JSON.stringify(action.payload))
            state.generalOptions = action.payload;
        },
        setKartogramOptions: (state, action) => {
            localStorage.setItem('am_KO', JSON.stringify(action.payload))
            state.kartogramOptions = action.payload;
        },
        setKartodiagramOptions: (state, action) => {
            localStorage.setItem('am_KdO', JSON.stringify(action.payload))
            state.kartodiagramOptions = action.payload;
        },
        setMapDimensions: (state, action) => {
            localStorage.setItem('am_MD', JSON.stringify(action.payload))
            state.mapDimensions = action.payload;
        }
    },
});

export const {
    setGeneralOptions,
    setKartogramOptions,
    setKartodiagramOptions,
    setMapDimensions,
} = AdvMapSlice.actions;

export const getGeneralOptions = state => state.AdvMap.generalOptions;
export const getKartogramOptions = state => state.AdvMap.kartogramOptions;
export const getKartodiagramOptions = state => state.AdvMap.kartodiagramOptions;
export const getMapDimensions = state => state.AdvMap.mapDimensions;

export const selectSelectedMapYear = state => state.AdvMap.generalOptions.selectedYear;
export const selectSelectedKartogramWskObj = state => state.AdvMap.kartogramOptions.wskObject;
export const selectSelectedKartodiagramWskObj = state => state.AdvMap.kartodiagramOptions.wskObject;

export default AdvMapSlice.reducer;
