import { createSlice } from "@reduxjs/toolkit";

/**
 * AdvMapDialogs Slice
 *
 * exports reducer ***AdvMap.reducer***
 *
 * ----
 *
 * @alias AdvMapDialogs
 *
 * @module AdvMapDialogs
 * @category . Redux store
 * */

export const AdvMapDialogsSlice = createSlice({
    name: "AdvMapDialogs",
    initialState: {
        generalSettings: localStorage.getItem("amd_GS")
            ? JSON.parse(localStorage.getItem("amd_GS"))
            : {
                  mouseMode: "pointing",
                  parentsBorders: 0,
                  presentMapFor: 0,
                  mouseColor: "#BBD531",
                  pointingColor: "#BBD531",
                  selectingColor: "#BBD531",
                  labelsColor: "#BBD531",
                  boundariesColor: "#000",
                  mapForColor: "#BBD531",
                  enableLegend: true,
                  enableScale: true,
                  enableTitle: true,
                  useShortNamesJT: false,
                  intervalsForSelectedAreasOnly: false,
                  selectedJtPresentMapFor: [],
                  selectedData: [],
                  highlightedData: [],
              },
        defaultGeneralSettings: {
            mouseMode: "pointing",
            parentsBorders: 0,
            presentMapFor: 0,
            mouseColor: "#BBD531",
            pointingColor: "#BBD531",
            selectingColor: "#BBD531",
            labelsColor: "#BBD531",
            boundariesColor: "#000",
            mapForColor: "#BBD531",
            enableLegend: true,
            enableScale: true,
            enableTitle: true,
            useShortNamesJT: false,
            intervalsForSelectedAreasOnly: false,
            selectedJtPresentMapFor: [],
            selectedData: [],
            highlightedData: [],
        },

        kartogramSettings: localStorage.getItem("amd_KS")
            ? JSON.parse(localStorage.getItem("amd_KS"))
            : {
                  draggable: false,
                  palettes: {
                      // paletteBlue: ['#C7DFF2', '#AFD0ED', '#93C0E7', '#71B2E1', '#4AA3DA', '#0D93D3', '#0074b1','#0087CD'],
                      paletteBlue: ["#C7DFF2", "#AFD0ED", "#93C0E7", "#71B2E1", "#4AA3DA", "#0D93D3", "#0074b1"],
                      paletteGreen: ["#DAECD4", "#CAE4C3", "#B8DCAE", "#A2D298", "#8DCA82", "#71C165", "#4CB848"],
                      // paletteCustom: ['#C7DFF2', '#AFD0ED', '#93C0E7', '#71B2E1', '#4AA3DA', '#0D93D3', '#0087CD']
                      paletteCustom: ["#C7DFF2", "#AFD0ED", "#93C0E7", "#71B2E1", "#4AA3DA", "#0D93D3", "#0074b1"],
                  },
                  // currentPalette: ['#C7DFF2', '#AFD0ED', '#93C0E7', '#71B2E1', '#4AA3DA', '#0D93D3', '#0087CD'],
                  currentPalette: ["#C7DFF2", "#AFD0ED", "#93C0E7", "#71B2E1", "#4AA3DA", "#0D93D3", "#0074b1"],
                  customPalette: "blue",
                  intervals: 5,//null, //5
                  intervalsMap: null,
                  classification: 1,
                  reverseScale: false,
                  openColorPicker: false,
                  noDataColor: "#CCCCCC",
                  apiMinMax: [],
              },
        defaultKartogramSettings: {
            draggable: false,
            // palettes: {
            //     paletteBlue: ['#EFF3FF', '#BDD7E7', '#6BAED6', '#3182BD', '#08519C', '#EFF3FF', '#BDD7E7', '#6BAED6', '#3182BD', '#08519C'],
            //     paletteGreen: ['#EDF8E9', '#BAE4B3', '#74C476', '#31A354', '#006D2C', '#EDF8E9', '#BAE4B3', '#74C476', '#31A354', '#006D2C'],
            //     paletteCustom: ['#EFF3FF', '#BDD7E7', '#6BAED6', '#3182BD', '#08519C', '#EFF3FF', '#BDD7E7', '#6BAED6', '#3182BD', '#08519C']
            // },
            // currentPalette: ['#EFF3FF', '#BDD7E7', '#6BAED6', '#3182BD', '#08519C', '#EFF3FF', '#BDD7E7', '#6BAED6', '#3182BD', '#08519C'],
            palettes: {
                // paletteBlue: ['#C7DFF2', '#AFD0ED', '#93C0E7', '#71B2E1', '#4AA3DA', '#0D93D3', '#0074b1','#0087CD'],
                paletteBlue: ["#C7DFF2", "#AFD0ED", "#93C0E7", "#71B2E1", "#4AA3DA", "#0D93D3", "#0074b1"],
                paletteGreen: ["#DAECD4", "#CAE4C3", "#B8DCAE", "#A2D298", "#8DCA82", "#71C165", "#4CB848"],
                // paletteCustom: ['#C7DFF2', '#AFD0ED', '#93C0E7', '#71B2E1', '#4AA3DA', '#0D93D3', '#0087CD']
                paletteCustom: ["#C7DFF2", "#AFD0ED", "#93C0E7", "#71B2E1", "#4AA3DA", "#0D93D3", "#0074b1"],
            },
            // currentPalette: ['#C7DFF2', '#AFD0ED', '#93C0E7', '#71B2E1', '#4AA3DA', '#0D93D3', '#0087CD'],
            currentPalette: ["#C7DFF2", "#AFD0ED", "#93C0E7", "#71B2E1", "#4AA3DA", "#0D93D3", "#0074b1"],
            customPalette: "blue",
            intervals: 5,
            intervalsMap: null,
            classification: 1,
            reverseScale: false,
            openColorPicker: false,
            noDataColor: "#CCCCCC",
            apiMinMax: [],
        },

        kartodiagramSettings: localStorage.getItem("amd_KdS")
            ? JSON.parse(localStorage.getItem("amd_KdS"))
            : {
                  classification: 1,
                  draggable: false,
                  intervals: 5,//null, //5,
                  intervalsMap: null,
                  shape: "circle",
                  openColorPicker: false,
                  shapeColor: "#E5790C",
                  shapeSize: "medium",
                  apiMinMax: [],
              },
        defaultKartodiagramSettings: {
            draggable: false,
            intervals: 5,
            intervalsMap: null,
            classification: 1,
            shape: "circle",
            openColorPicker: false,
            shapeColor: "#E5790C",
            shapeSize: "medium",
            apiMinMax: [],
        },
    },
    reducers: {
        setGeneralSettings: (state, action) => {
            localStorage.setItem("amd_GS", JSON.stringify(action.payload));
            state.generalSettings = action.payload;
        },
        setKartogramSettings: (state, action) => {
            localStorage.setItem("amd_KS", JSON.stringify(action.payload));
            state.kartogramSettings = action.payload;
        },
        setKartodiagramSettings: (state, action) => {
            localStorage.setItem("amd_KdS", JSON.stringify(action.payload));
            state.kartodiagramSettings = action.payload;
        },
    },
});

export const { setGeneralSettings, setKartogramSettings, setKartodiagramSettings } = AdvMapDialogsSlice.actions;

export const getGeneralSettings = (state) => state.AdvMapDialogs.generalSettings;
export const getDefaultGeneralSettings = (state) => state.AdvMapDialogs.defaultGeneralSettings;
export const getKartogramSettings = (state) => state.AdvMapDialogs.kartogramSettings;
export const getDefaultKartogramSettings = (state) => state.AdvMapDialogs.defaultKartogramSettings;
export const getKartodiagramSettings = (state) => state.AdvMapDialogs.kartodiagramSettings;
export const getDefaultKartodiagramSettings = (state) => state.AdvMapDialogs.defaultKartodiagramSettings;

export default AdvMapDialogsSlice.reducer;
