import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { LOCAL_TEST, BASE_URL } from "../API/apiConfig";
import { setAppError } from "./APP";

/**
 * Areas Slice
 *
 * exports reducer ***AreasSlice.reducer***
 *
 * ----
 *
 * @alias AreasSlice
 *
 * @example
 *
 * import {useSelector, useDispatch} from 'react-redux';
 * import {selectAreas, setCurrentArea} from 'path/to/appStore/Areas'
 *
 * ...
 *
 * const MyComponent = () => {
 *     const dispatch = useDispatch();
 *
 *     //select
 *     const areas = useSelector(selectAreas);
 *     // - or -
 *     const areas = useSelector(state=>state.areas.areas)
 *
 *     //set current area
 *     dispatch(setCurrentArea(ArrayOfObjects));
 *
 *     ...
 *
 *     return(
 *         <pre>{JSON.stringify(areas, null, 4)}</pre>
 *     )
 *
 * }
 * export default MyComponent
 *
 * @param {Object} initialState
 * @param {Object[]} initialState.areas *array of objects* ***Obszary***
 * @param {Object} initialState.currentArea *Selected (current object)* ***Obszar***
 * @param {Object[]} initialState.services *array of objects* ***Uslugi***
 * @param {Object} initialState.selectedService *Selected (current object)* ***Usluga***
 * @param {Object[]} initialState.pointers *array of objects* ***Wskaźniki***
 * @param {Object} initialState.selectedPointer *Selected (current object)* ***Wskaźnik***
 *
 * @param {} selectors
 * @param {function} selectors.selectAreas returns array of objects ***Obszary***
 * @param {function} selectors.selectCurrentArea returns object (current) ***Obszar***
 * @param {function} selectors.selectServices returns array of objects ***Uslugi***
 * @param {function} selectors.selectSelectedService returns object (current) ***Usluga***
 * @param {function} selectors.selectPointers returns array of objects ***Wskaźniki***
 * @param {function} selectors.selectSelectedPointer returns object (current) ***Wskaźnik***
 *
 * @param {Object} reducers
 * @param {function} reducers.setAreas Redux action to set `areas`
 * @param {function} reducers.setCurrentArea Redux action to set `currentArea`
 * @param {function} reducers.setServices Redux action to set `services`
 * @param {function} reducers.setSelectedService Redux action to set `selectedService`
 * @param {function} reducers.setPointers Redux action to set `pointers`
 * @param {function} reducers.setSelectedPointer Redux action to set `selectedPointer`
 *
 * @param {function} fetchAreas Redux action creator to fetch data from api ***`${BASE_URL}Slowniki/GetObszaryUslug`***,

   and set `areas` and `currentArea`

 * @module AreasSlice
 * @category . Redux store
 * */

export const AreasSlice = createSlice({
    name: "areas",
    initialState: {
        areas: [],
        currentArea: localStorage.getItem("cA") ? JSON.parse(localStorage.getItem("cA")) : null,
        services: localStorage.getItem("Ss") ? JSON.parse(localStorage.getItem("Ss")) : [],//[] /** Data for FirstSelect on Basic page */,
        selectedService: localStorage.getItem("sS") ? JSON.parse(localStorage.getItem("sS")) : {},//{},
        pointers: localStorage.getItem("Ps") ? JSON.parse(localStorage.getItem("Ps")) : [],//[] /** Data for SecondSelect on Basic page */,
        selectedPointer: localStorage.getItem("sP") ? JSON.parse(localStorage.getItem("sP")) : null,//{},
        fromShare: false
    },
    reducers: {
        setAreas: (state, action) => {
            state.areas = action.payload;
        },
        setCurrentArea: (state, action) => {
            localStorage.setItem("cA", JSON.stringify(action.payload));
            state.currentArea = action.payload;
        },
        setServices: (state, action) => {
            localStorage.setItem("Ss", JSON.stringify(action.payload));
            state.services = action.payload;
        },
        setSelectedService: (state, action) => {
            localStorage.setItem("sS", JSON.stringify(action.payload));
            state.selectedService = action.payload;
        },
        setPointers: (state, action) => {
            localStorage.setItem("Ps", JSON.stringify(action.payload));
            state.pointers = action.payload;
        },
        setSelectedPointer: (state, action) => {
            localStorage.setItem("sP", JSON.stringify(action.payload));
            state.selectedPointer = action.payload;
        },
        setFromShare: (state, action) => {
            state.fromShare = action.payload;
        },
    },
});

export const fetchAreas = () => (dispatch, getState) => {
    const apiURL = LOCAL_TEST ? "./devData/GetObszaryUslug.json" : `${BASE_URL}Slowniki/GetObszaryUslug`;
    const _currentArea = getState()?.areas?.currentArea;
    // console.log(_currentArea)
    axios
        .get(apiURL)
        .then((response) => {
            // console.log(response.data);
            if (response?.data?.length === 9) {
                response.data = [
                    ...response.data,
                    { ...response.data[8], isFin: true, id_ou: 10, nazwa_ou: "MODUŁ FINANSOWY MF" },
                ];
            }
            dispatch(setAreas(response.data));

            dispatch(setCurrentArea(_currentArea || response.data.filter((x) => x.czy_domyslny === true)[0]));

            dispatch(setAppError(getState().APP.appError.filter((e) => e.type !== "getAreas")));
        })
        .catch((error) => {
            dispatch(
                setAppError([
                    ...getState().APP.appError,
                    { type: "getAreas", message: `Can't get AREAs: ${error.message}` },
                ])
            );
        })
        .finally(() => {});
};

export const { setAreas, setCurrentArea, setServices, setSelectedService, setPointers, setSelectedPointer, setFromShare } =
    AreasSlice.actions;

export const selectAreas = (state) => state.areas.areas;
export const selectCurrentArea = (state) => state.areas.currentArea;
export const selectServices = (state) => state.areas.services;
export const selectSelectedService = (state) => state.areas.selectedService;
export const selectPointers = (state) => state.areas.pointers;
export const selectSelectedPointer = (state) => state.areas.selectedPointer;
export const getFromShare = (state) => state.areas.fromShare;

export default AreasSlice.reducer;
