import { createSlice } from "@reduxjs/toolkit";

export const BasicAccordionSlice = createSlice({
    name: "BasicAccordion",
    initialState: {
        expandedAccordion: "bars",
        limitDate4LineChart: localStorage.getItem("ld4lc") ? JSON.parse(localStorage.getItem("ld4lc")) : false,
    },
    reducers: {
        setExpandedAccordion: (state, action) => {
            state.expandedAccordion = action.payload;
        },
        setLimitDate4LineChart: (state, action) => {
            localStorage.setItem("ld4lc", JSON.stringify(action.payload));
            state.limitDate4LineChart = action.payload;
        },
    },
});

export const { setExpandedAccordion, setLimitDate4LineChart } = BasicAccordionSlice.actions;

export const selectedExpandedAccordion = (state) => state.BasicAccordion.expandedAccordion;
export const getLimitDate4LineChart = (state) => state.BasicAccordion.limitDate4LineChart;

export default BasicAccordionSlice.reducer;
