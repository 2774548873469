import { createSlice } from '@reduxjs/toolkit';


export const AdvancedApiDataSlice = createSlice({
    name: 'AdvancedApiData',
    initialState: {
        TableData: localStorage.getItem('api_TD')
            ? JSON.parse(localStorage.getItem('api_TD'))
            : {},
        currentTableData: localStorage.getItem('api_cTD')
            ? JSON.parse(localStorage.getItem('api_cTD'))
            : {},
        loadingTableData: false,

        ChartsData: localStorage.getItem('api_CD')
            ? JSON.parse(localStorage.getItem('api_CD'))
            : {},
        currentChartsData: localStorage.getItem('api_cCD')
            ? JSON.parse(localStorage.getItem('api_cCD'))
            : {},
        loadingChartsData: false,

        LineChartsData: localStorage.getItem('api_lCD')
            ? JSON.parse(localStorage.getItem('api_lCD'))
            : {},
        currentLineChartsData: localStorage.getItem('api_clCD')
            ? JSON.parse(localStorage.getItem('api_clCD'))
            : {},
        loadingLineChartsData: false,

        MapsData: localStorage.getItem('api_MD')
            ? JSON.parse(localStorage.getItem('api_MD'))
            : {},
        currentMapsData: localStorage.getItem('api_cMD')
            ? JSON.parse(localStorage.getItem('api_cMD'))
            : {},
        loadingMapsData: false,

        datasForReport: {}
    },
    reducers: {
        setTableData: (state, action) => {
            // localStorage.setItem('api_TD', JSON.stringify(action.payload))
            state.TableData = action.payload;
        },
        setCurrentTableData: (state, action) => {
            // localStorage.setItem('api_cTD', JSON.stringify(action.payload))
            state.currentTableData = action.payload;
        },
        setLoadingTableData: (state, action) => {
            state.loadingTableData = action.payload;
        },

        setChartsData: (state, action) => {
            // localStorage.setItem('api_CD', JSON.stringify(action.payload))
            state.ChartsData = action.payload;
        },
        setCurrentChartsData: (state, action) => {
            // localStorage.setItem('api_cCD', JSON.stringify(action.payload))
            state.currentChartsData = action.payload;
        },
        setLoadingChartsData: (state, action) => {
            state.loadingChartsData = action.payload;
        },

        setLineChartsData: (state, action) => {
            // localStorage.setItem('api_lCD', JSON.stringify(action.payload))
            state.LineChartsData = action.payload;
        },
        setCurrentLineChartsData: (state, action) => {
            // localStorage.setItem('api_clCD', JSON.stringify(action.payload))
            state.currentLineChartsData = action.payload;
        },
        setLoadingLineChartsData: (state, action) => {
            state.loadingLineChartsData = action.payload;
        },

        setMapsData: (state, action) => {
            // localStorage.setItem('api_MD', JSON.stringify(action.payload))
            state.MapsData = action.payload;
        },
        setCurrentMapsData: (state, action) => {
            // localStorage.setItem('api_cMD', JSON.stringify(action.payload))
            state.currentMapsData = action.payload;
        },
        setLoadingMapsData: (state, action) => {
            state.loadingMapsData = action.payload;
        },

        setReportData: (state, action) => {
            state.datasForReport = action.payload;
        },
    },
});

export const {
    setTableData,
    setCurrentTableData,
    setLoadingTableData,

    setChartsData,
    setCurrentChartsData,
    setLoadingChartsData,

    setLineChartsData,
    setCurrentLineChartsData,
    setLoadingLineChartsData,

    setMapsData,
    setCurrentMapsData,
    setLoadingMapsData,

    setReportData

} = AdvancedApiDataSlice.actions;

export const selectTableData = state => state.AdvancedApiData.TableData;
export const selectCurrentTableData = state => state.AdvancedApiData.currentTableData;
export const selectLoadingTableData = state => state.AdvancedApiData.loadingTableData;

export const selectChartsData = state => state.AdvancedApiData.ChartsData;
export const selectCurrentChartsData = state => state.AdvancedApiData.currentChartsData;
export const selectLoadingChartsData = state => state.AdvancedApiData.loadingChartsData

export const selectLineChartsData = state => state.AdvancedApiData.LineChartsData;
export const selectCurrentLineChartsData = state => state.AdvancedApiData.currentLineChartsData;
export const selectLoadingLineChartsData = state => state.AdvancedApiData.loadingLineChartsData;

export const selectMapsData = state => state.AdvancedApiData.MapsData;
export const selectCurrentMapsData = state => state.AdvancedApiData.currentMapsData;
export const selectLoadingMapsData = state => state.AdvancedApiData.loadingMapsData;

export const selectReportData = state => state.AdvancedApiData.datasForReport;

export default AdvancedApiDataSlice.reducer;
