import { createMuiTheme } from "@material-ui/core/styles";
import { fade } from "@material-ui/core";

const defaultTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#001D77",
        },
        secondary: {
            main: "#b9d330",
        },
        error: {
            main: "#D50000",
        },
    },
    typography: {
        htmlFontSize: 18,
        // fontFamily: 'Roboto, sans-serif',
        fontFamily: "'Open Sans', sans-serif",
        fontSize: 18,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            // fontFamily: 'Roboto, sans-serif',
            fontWeight: 300,
            fontSize: "6rem",
            lineHeight: 1.167,
            letterSpacing: "-0.01562em",
        },
        h2: {
            // fontFamily: 'Roboto, sans-serif',
            fontWeight: 300,
            fontSize: "3.75rem",
            lineHeight: 1.2,
            letterSpacing: "-0.00833em",
        },
        h3: {
            // fontFamily: 'Roboto, sans-serif',
            fontWeight: 300,
            fontSize: "3rem",
            lineHeight: 1.167,
            letterSpacing: "0em",
        },
        h4: {
            // fontFamily: 'Roboto, sans-serif',
            fontWeight: 300,
            fontSize: "2.125rem",
            lineHeight: 1.235,
            letterSpacing: "0.00735em",
        },
        h5: {
            // fontFamily: 'Roboto, sans-serif',
            fontWeight: 300,
            fontSize: "1.5rem",
            lineHeight: 1.334,
            letterSpacing: "0em",
        },
        h6: {
            // fontFamily: 'Roboto, sans-serif',
            fontWeight: 500,
            fontSize: "1.25rem",
            lineHeight: 1.6,
            letterSpacing: "0.0075em",
        },
        subtitle1: {
            // fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.75,
            letterSpacing: "0.00938em",
        },
        subtitle2: {
            // fontFamily: 'Roboto, sans-serif',
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.57,
            letterSpacing: "0.00714em",
        },
        body1: {
            // fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
        },
        body2: {
            // fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: 1.43,
            letterSpacing: "0.01071em",
        },
        button: {
            // fontFamily: 'Roboto, sans-serif',
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.75,
            letterSpacing: "0.02857em",
            textTransform: "uppercase",
        },
        caption: {
            // fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 1.66,
            letterSpacing: "0.03333em",
        },
        overline: {
            // fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 2.66,
            letterSpacing: "0.08333em",
            textTransform: "uppercase",
        },
    },
    components: {
        MuiIconButton: {
            variants: [
                {
                    props: { variant: "acsent", color: "primary" },
                    style: {
                        background: "transparent",
                        color: "#001D77",
                    },
                },
            ],
        },
    },
});

// console.log(defaultTheme);

const { breakpoints } = defaultTheme;

const outline = {
    outlineOffset: 3,
    outlineColor: "#101010",
    outlineStyle: "solid",
    outlineWidth: 2,
};

const theme = {
    ...defaultTheme,
    overrides: {
        MuiCssBaseline: {
            "@global": {
                html: {
                    fontSize: 18,
                    [breakpoints.down("sm")]: {
                        fontSize: "0.875rem",
                    },
                    [breakpoints.down("xs")]: {
                        fontSize: "0.75rem",
                    },
                    WebkitFontSmoothing: "auto",
                    // overflowY: 'auto',
                    // minWidth: '100vw',
                    minHeight: "100vh",
                    // overflowX: 'hidden',
                    padding: 0,
                    margin: 0,
                },
                body: {
                    // fontFamily: 'Roboto, sans-serif',
                    fontFamily: "'Open Sans', sans-serif",
                    padding: "0!important",
                    margin: 0,
                    fontSize: 18,
                    fontWeight: 400,
                    backgroundColor: "#FFFFFF",
                    // overflow: "visible!important",
                    overflow: "visible",
                    "& :focus-visible": {
                        ...outline,
                    },
                },
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: "#000000",
            },
        },
        MuiButton: {
            root: {
                borderRadius: 23,
                // "&:focus-visible": {
                "&:focus": {
                    ...outline,
                },
            },
            contained: {
                // background: 'linear-gradient(45deg, #282780 0%, #007ABD 100%)',
                background: "transparent linear-gradient(249deg, #007ABD 0%, #282780 100%) 0% 0% no-repeat padding-box",
                "&.Mui-disabled": {
                    color: "#AAA",
                },
            },
            containedPrimary: {
                // textShadow: `-1px 0 ${defaultTheme.palette.primary.main}, 0 1px ${defaultTheme.palette.primary.main}, 1px 0 ${defaultTheme.palette.primary.main}, 0 -1px ${defaultTheme.palette.primary.main}`,
            },
        },
        MuiIconButton: {
            root: {
                // "&:focus-visible": {
                "&:focus-visible": {
                    ...outline,
                },
                "&:hover": {
                    backgroundColor: "transparent",
                },
            },
            colorPrimary: {
                // background: 'transparent linear-gradient(249deg, #007ABD 0%, #282780 100%) 0% 0% no-repeat padding-box',
                background: defaultTheme.palette.primary.main,
                "&:hover": {
                    background: defaultTheme.palette.primary.light,
                    backgroundColor: `${defaultTheme.palette.primary.dark}`,
                    boxShadow: `0 0 2px 2px ${defaultTheme.palette.primary.dark}, 
                        inset -1px 0px 1px rgba(255, 255, 255,1), 
                        inset 1px 0px 1px rgba(255, 255, 255,1), 
                        inset 0px -1px 1px rgba(255, 255, 255,1), 
                        inset 0px 1px 1px rgba(255, 255, 255,1)`,
                    // boxShadow: `0 0 2px rgba(0, 20, 83,1),
                    //     inset -1px 0px 1px ${defaultTheme.palette.primary.dark},
                    //     inset 1px 0px 1px ${defaultTheme.palette.primary.dark},
                    //     inset 0px -1px 1px ${defaultTheme.palette.primary.dark},
                    //     inset 0px 1px 1px ${defaultTheme.palette.primary.dark}`,
                },
                color: "#FFF",
                padding: defaultTheme.spacing(1),
                margin: defaultTheme.spacing(0, 1, 0, 0),
                "&.Mui-disabled": {
                    // color: "#AAA!important",
                    color: "#AAA",
                    // background: defaultTheme.palette.primary.light,
                },
            },
        },
        MuiCheckbox: {
            root: {
                // "&:focus": {
                //     ...outline
                // },
            },
            colorPrimary: {
                background: "transparent",
                "&:hover": {
                    background: "transparent",
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    boxShadow: "none",
                },
                // color: defaultTheme.palette.primary.main,
                padding: defaultTheme.spacing(1),
                "&.Mui-disabled": {
                    color: "#AAA!important",
                },
                "&.Mui-checked": {
                    color: defaultTheme.palette.primary.main,
                },
            },
        },
        MuiRadio: {
            root: {},
            colorPrimary: {
                background: "transparent",
                "&:hover": {
                    background: "transparent",
                    backgroundColor: "transparent",
                },
                // color: defaultTheme.palette.primary.main,
                padding: defaultTheme.spacing(1),
                "&.Mui-disabled": {
                    color: "#AAA!important",
                },
                "&.Mui-checked": {
                    color: defaultTheme.palette.primary.main,
                },
            },
        },
        MuiSwitch: {
            switchBase: {
                color: "#757575",
            },
            track: {
                opacity: 0.15,
            },
        },
        MuiTooltip: {
            popper: {
                position: "fixed",
                [defaultTheme.breakpoints.down("xs")]: {
                    display: "none",
                },
            },
            tooltip: {
                // backgroundColor: "#fff",
                // color: "rgba(0, 0, 0, 0.87)",
                color: "#fff",
                backgroundColor: "#616161",
                // maxWidth: 320,
                width: "auto",
                fontSize: defaultTheme.typography.pxToRem(13),
                border: "none",
                boxShadow: "rgb(174,174,174) 0px 0px 5px",
                borderRadius: 4,
                padding: defaultTheme.spacing(1, 2),
            },
        },
        MuiTab: {
            root: {
                // "&:focus-visible": {
                "&:focus-visible": {
                    ...outline,
                },
            },
        },
    },
    shape: {
        ...defaultTheme.shape,
        boxRadius: 20,
        boxedWrapper: {
            backgroundColor: "#FFF",
            // boxShadow: `0 0 36px ${fade(defaultTheme.palette.primary.main, 0.1)}`,
            boxShadow: "3px 9px 35px #0000000D",
            padding: defaultTheme.spacing(2, 3),
            borderRadius: 20,
            border: "0.5px solid #D2D6DA",
            [defaultTheme.breakpoints.down("xs")]: {
                padding: defaultTheme.spacing(1, 2),
                borderRadius: 20,
            },
        },
    },
    props: {
        MuiTooltip: {
            // disableFocusListener: true,
            // interactive: true,
            onOpen: () => document.addEventListener("keydown", handleKeyDownForTooltip),
            onClose: () => document.removeEventListener("keydown", handleKeyDownForTooltip),
        },
        MuiButton: {
            disableFocusRipple: true,
        },
        MuiIconButton: {
            disableFocusRipple: true,
        },
        MuiTab: {
            disableFocusRipple: true,
        },
        MuiFab: {
            disableFocusRipple: true,
        },
        MuiDialog: {
            disableEscapeKeyDown: true,
        },
    },
    outline,
};

// console.log({ theme })

export default theme;

function handleKeyDownForTooltip(nativeEvent) {
    if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        let _el = document?.querySelector(".MuiTooltip-popper");

        if (_el && _el.parentNode) {
            // _el.style.display = "none";
            _el.removeAttribute("style");
        }
    }
}
