const SET_COLOR_SERIES = 'SET_COLOR_SERIES';
const SET_COLOR_HIGHLIGHT = 'SET_COLOR_HIGHLIGHT';
const SET_CHART = 'SET_CHART';
const SET_COLOR_LINE = 'SET_COLOR_LINE';
const SET_LEGEND = 'SET_LEGEND';
const SET_COLOR_BAR = 'SET_COLOR_BAR';
const SET_LEGEND_LINE = 'SET_LEGEND_LINE';
const SET_DEFAULT_WSK = 'SET_DEFAULT_WSK';
const SET_DEFAULT_YEAR = 'SET_DEFAULT_YEAR';
const SET_ABR_NAMES_OF_JT = 'SET_ABR_NAMES_OF_JT';
const SET_BAR_CHART_NAME = 'SET_BAR_CHART_NAME';
const SET_LINE_CHART_NAME ='SET_LINE_CHART_NAME';
const SET_BAR_DATA = 'SET_BAR_DATA';
const SET_LINE_DATA = 'SET_LINE_DATA';
const SHOW_LABEL_DATA = 'SHOW_LABEL_DATA';
const SET_DEFAULT_JT = 'SET_DEFAULT_JT';
const SET_FROM_YEAR = 'SET_FROM_YEAR';
const SET_TO_YEAR = 'SET_TO_YEAR';
const SET_SELECTED_WSK = 'SET_SELECTED_WSK';
const SET_SELECTED_JTS = 'SET_SELECTED_JTS';
const SET_SLICED_DATA_LINE = 'SET_SLICED_DATA_LINE';
const SET_MULTI_BAR_DATA = 'SET_MULTI_BAR_DATA';
const SET_SECOND_WSK = 'SET_SECOND_WSK';
const SET_SAVE_LEGEND_IN_SETTINGS = 'SET_SAVE_LEGEND_IN_SETTINGS';
const SET_SECOND_LEGEND = 'SET_SECOND_LEGEND';
const SET_BUTTONSORT_90 = 'SET_BUTTONSORT_90';
const SET_BUTTONSORT_09 = 'SET_BUTTONSORT_09';
const SET_BUTTONSORT_AB = 'SET_BUTTONSORT_AB';
const SET_BUTTONSORT_BA = 'SET_BUTTONSORT_BA';

const initialState = {
    colorOfSeries:'',
    colorOfHighlight:'#228b22',
    bar:true,
    line:false,
    colorLine:'#000080',
    colorBar:'#93ccea',
    legend: true,
    legendLine:true,
    defaultWSK : null,
    defaultYear: null,
    defaultJT: null,
    abbreviatedNamesOfJT: true,
    barChartName:'',
    lineChartName:'',
    barData:[],
    lineData:[], 
    showLabelData: false,
    fromYear: null,
    toYear:null,
    wskSelected:null,
    selectedJTS:[],
    slicedDataLine:[],
    secondWSK: [],
    secondJT:[],
    secondLegend: null,
    multipleBarData:[], 
    saveSettings:{
        legend: true,
        legendLine:true,
        abbreviatedNamesOfJT: true,
        showLabelData: false,
    },
    buttonSort90: true,
    buttonSort09:false,
    buttonSortAB: false,
    buttonSortBA: false
    
}

const ChartsReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_COLOR_SERIES :
            return {
                ...state,
                colorOfSeries: action.payload,
            };

        case SET_COLOR_HIGHLIGHT : 
             return {
            ...state,
            colorOfHighlight: action.payload,
            };

        case SET_CHART :
            return {
            ...state,
            ...action.payload
            };

        case SET_COLOR_LINE :
            return {
            ...state,
            colorLine: action.payload
            };

        case SET_COLOR_BAR :
            return {
            ...state,
            colorBar: action.payload
            };

        case SET_LEGEND :
            return{
            ...state,
            legend: action.payload
            };
            
        case SET_LEGEND_LINE :
            return {
            ...state,
            legendLine: action.payload
            };
        case SET_DEFAULT_WSK :
            return {
            ...state,
            defaultWSK: action.payload
            };
        case SET_DEFAULT_YEAR :
            return {
            ...state,
            defaultYear: action.payload
            };
        case SET_ABR_NAMES_OF_JT :
            return {
            ...state,
            abbreviatedNamesOfJT: action.payload
            };
        case SET_BAR_CHART_NAME :
            return {
            ...state,
            barChartName: action.payload
            };
        case SET_LINE_CHART_NAME :
            return {
            ...state,
            lineChartName: action.payload
            };
        case SET_BAR_DATA :
            return {
            ...state,
            barData: action.payload
            };
        case SET_LINE_DATA :
             return {
            ...state,
            lineData: action.payload
            };  
        case SHOW_LABEL_DATA :
            return {
            ...state,
            showLabelData: action.payload
            };    
        case  SET_DEFAULT_JT:
            return {
            ...state,
            defaultJT: action.payload
            }; 
        case SET_FROM_YEAR:
            return {
            ...state,
            fromYear: action.payload
            }; 
        case  SET_TO_YEAR:
            return {
            ...state,
            toYear: action.payload
            }; 
        case  SET_SELECTED_WSK:
           return {
             ...state,
             wskSelected: action.payload
            }; 
        case  SET_SELECTED_JTS:
            return {
             ...state,
             selectedJTS: action.payload
            }; 
        case  SET_SLICED_DATA_LINE:
            return {
             ...state,
             slicedDataLine: action.payload
            }; 

        case  SET_MULTI_BAR_DATA:
             return {
              ...state,
              multipleBarData: action.payload
            }; 
        case  SET_SECOND_WSK:
             return {
             ...state,
             secondWSK: action.payload
             }; 
        case  SET_SAVE_LEGEND_IN_SETTINGS:
            return {
            ...state,
            saveSettings: action.payload
            }; 
        case SET_SECOND_LEGEND:
            return {
            ...state,
            secondLegend: action.payload
            }; 
        case SET_BUTTONSORT_90:
            return{
            ...state, 
            buttonSort90: action.payload
            };
        case SET_BUTTONSORT_09:
            return{
            ...state, 
            buttonSort09: action.payload
            };
        case SET_BUTTONSORT_AB:
            return {
            ...state,
            buttonSortAB: action.payload
            };
        case SET_BUTTONSORT_BA:
            return {
            ...state,
            buttonSortBA: action.payload
            }
        default:
            return state;
    }
}


export default ChartsReducer;


export const setColorOfSeries = colorOfSeries =>({
    type:'SET_COLOR_SERIES',
    payload: colorOfSeries
});

export const setColorOfHighlight = (colorOfHighlight) => ({
    type: 'SET_COLOR_HIGHLIGHT',
    payload: colorOfHighlight,
});

export const setChart = (bar, line) =>({
    type: 'SET_CHART',
    payload: {bar, line}
});

export const setColorOfLine =(colorLine) =>({
    type:'SET_COLOR_LINE',
    payload: colorLine
});

export const setLegend = (legend) =>({
    type:'SET_LEGEND',
    payload: legend
});

export const setColorOfBar =(colorBar) =>({
    type:'SET_COLOR_BAR',
    payload: colorBar
});

export const setLegendLine = (legendLine) =>({
    type: 'SET_LEGEND_LINE',
    payload: legendLine
});

export const setDefaultWSK = (defaultWSK) => ({
    type: 'SET_DEFAULT_WSK',
    payload: defaultWSK
});

export const setDefaultYear = (defaultYear) => ({
    type: 'SET_DEFAULT_YEAR',
    payload: defaultYear
});

export const setAbbreviatedNamesOfJT = (abbreviatedNamesOfJT)=> ({
    type: 'SET_ABR_NAMES_OF_JT',
    payload: abbreviatedNamesOfJT
});

export const setBarChartName = ( barChartName)=> ({
    type: 'SET_BAR_CHART_NAME',
    payload:  barChartName
});

export const setLineChartName = ( lineChartName)=> ({
    type: 'SET_LINE_CHART_NAME',
    payload: lineChartName
});

export const setBarData = (barData)=> ({
    type: 'SET_BAR_DATA',
    payload: barData
});

export const setLineData = (lineData)=> ({
    type: 'SET_LINE_DATA',
    payload: lineData
});

export const setLabelData = (showLabelData)=> ({
    type: 'SHOW_LABEL_DATA',
    payload: showLabelData
});

export const setDefaultJT = (defaultJT)=> ({
    type: 'SET_DEFAULT_JT',
    payload: defaultJT
});

export const setFromYear = (fromYear)=> ({
    type: 'SET_FROM_YEAR',
    payload: fromYear
});

export const setToYear = (toYear)=> ({
    type: 'SET_TO_YEAR',
    payload: toYear
});

export const setSelectedWsk = (wskSelected)=> ({
    type: 'SET_SELECTED_WSK',
    payload: wskSelected
});

export const setSelectedJTSToLineChart = (selectedJTS)=> ({
    type:'SET_SELECTED_JTS',
    payload:  selectedJTS
});

export const setSlicedDataLine = (slicedDataLine)=> ({
    type: 'SET_SLICED_DATA_LINE',
    payload: slicedDataLine
});

export const setMultiBarData = ( multipleBarData)=> ({
    type: 'SET_MULTI_BAR_DATA',
    payload:  multipleBarData
});

export const setSecondWSK = (secondWSK)=> ({
    type: 'SET_SECOND_WSK',
    payload: secondWSK
});

export const setSaveLegendInSettings = (legend,abbreviatedNamesOfJT,showLabelData)=> ({
    type: 'SET_SAVE_LEGEND_IN_SETTINGS',
    payload: {legend,abbreviatedNamesOfJT,showLabelData}
});

export const setSecondLegend = (secondLegend)=> ({
    type: 'SET_SECOND_LEGEND',
    payload: secondLegend
});

export const setButtonSort90 = (buttonSort90)=> ({
    type: 'SET_BUTTONSORT_90',
    payload: buttonSort90
});

export const setButtonSort09 = (buttonSort09)=> ({
    type: 'SET_BUTTONSORT_09',
    payload: buttonSort09
});

export const setButtonSortAB = (buttonSortAB)=> ({
    type: 'SET_BUTTONSORT_AB',
    payload: buttonSortAB
});

export const setButtonSortBA = (buttonSortBA)=> ({
    type: 'SET_BUTTONSORT_BA',
    payload: buttonSortBA
});