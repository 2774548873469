import { createSlice } from "@reduxjs/toolkit";

/**
 * AdvancedJT Slice
 *
 * exports reducer ***AdvancedJT.reducer***
 *
 * ----
 *
 * @alias AdvancedJT
 *
 * @module AdvancedJT
 * @category . Redux store
 * */

export const AdvancedJTSlice = createSlice({
    name: "AdvancedJT",
    initialState: {
        territorialUnits: localStorage.getItem("appTU")
            ? JSON.parse(localStorage.getItem("appTU"))
            : { KTS: [], TERYT: [] },
        selectedTerritorialUnitsItems: localStorage.getItem("sTUI") ? JSON.parse(localStorage.getItem("sTUI")) : [],
        territorialUnitsType: localStorage.getItem("TUT") ? localStorage.getItem("TUT") : "TERYT",
        expanded: localStorage.getItem("eTUI") ? JSON.parse(localStorage.getItem("eTUI")) : ["0|17"],
        scrolled: null,
        scrollTop: localStorage.getItem("tstTUI") ? JSON.parse(localStorage.getItem("tstTUI")) : 0,
        simpleTree: false,
        largePanel: {
            open: false,
            tabIndex: 0,
        },
    },
    reducers: {
        setTerritorialUnits: (state, action) => {
            // localStorage.setItem('appTU', JSON.stringify(action.payload))
            state.territorialUnits = action.payload;
        },
        setSelectedTerritorialUnitsItem: (state, action) => {
            localStorage.setItem("sTUI", JSON.stringify(action.payload));
            state.selectedTerritorialUnitsItems = action.payload;
        },
        setTerritorialUnitsType: (state, action) => {
            localStorage.setItem("TUT", action.payload);
            state.territorialUnitsType = action.payload;
        },
        setExpanded: (state, action) => {
            localStorage.setItem("eTUI", JSON.stringify(action.payload));
            state.expanded = action.payload;
        },
        setScrolled: (state, action) => {
            state.scrolled = action.payload;
        },
        setScrollTop: (state, action) => {
            localStorage.setItem("tstTUI", action.payload);
            state.scrollTop = action.payload;
        },
        setSimpleTree: (state, action) => {
            state.simpleTree = action.payload;
        },
        setLargePanel: (state, action) => {
            state.largePanel = action.payload;
        },
    },
});

export const {
    setSelectedTerritorialUnitsItem,
    setTerritorialUnitsType,
    setTerritorialUnits,
    setExpanded,
    setScrolled,
    setSimpleTree,
    setLargePanel,
    setScrollTop
} = AdvancedJTSlice.actions;

export const selectSelectedTerritorialUnitsItems = (state) => state.AdvancedJT.selectedTerritorialUnitsItems;
export const selectTerritorialUnitsType = (state) => state.AdvancedJT.territorialUnitsType;
export const selectTerritorialUnits = (state) => state.AdvancedJT.territorialUnits;
export const selectExpanded = (state) => state.AdvancedJT.expanded;
export const selectScrolled = (state) => state.AdvancedJT.scrolled;
export const selectSimpleTree = (state) => state.AdvancedJT.simpleTree;
export const selectLargePanel = (state) => state.AdvancedJT.largePanel;
export const getScrollTop = (state) => state.AdvancedJT.scrollTop;

export default AdvancedJTSlice.reducer;
