import { createSlice } from "@reduxjs/toolkit";

export const AdvDimensionsSlice = createSlice({
    name: "AdvDimensions",
    initialState: {
        selectedTablePrzekroje: localStorage.getItem("s_TP") ? JSON.parse(localStorage.getItem("s_TP")) : [],
        defaultTablePrzekroje: localStorage.getItem("s_dTP") ? JSON.parse(localStorage.getItem("s_dTP")) : [],

        selectedChartsPrzekroje: localStorage.getItem("s_CHP") ? JSON.parse(localStorage.getItem("s_CHP")) : [],
        defaultChartsPrzekroje: localStorage.getItem("s_dCHP") ? JSON.parse(localStorage.getItem("s_dCHP")) : [],

        selectedLineChartsPrzekroje: localStorage.getItem("s_lCHP") ? JSON.parse(localStorage.getItem("s_lCHP")) : [],
        defaultLineChartsPrzekroje: localStorage.getItem("s_dlCHP") ? JSON.parse(localStorage.getItem("s_dlCHP")) : [],

        selectedMapsPrzekroje: localStorage.getItem("s_MP") ? JSON.parse(localStorage.getItem("s_MP")) : [],
        defaultMapsPrzekroje: localStorage.getItem("s_dMP") ? JSON.parse(localStorage.getItem("s_dMP")) : [],
    },
    reducers: {
        setSelectedTablePrzekroje: (state, action) => {
            localStorage.setItem("s_TP", JSON.stringify(action.payload));
            state.selectedTablePrzekroje = action.payload;
        },
        setDefaultTablePrzekroje: (state, action) => {
            localStorage.setItem("s_dTP", JSON.stringify(action.payload));
            state.defaultTablePrzekroje = action.payload;
        },

        setSelectedChartsPrzekroje: (state, action) => {
            localStorage.setItem("s_CHP", JSON.stringify(action.payload));
            state.selectedChartsPrzekroje = action.payload;
        },
        setDefaultChartsPrzekroje: (state, action) => {
            localStorage.setItem("s_dCHP", JSON.stringify(action.payload));
            state.defaultChartsPrzekroje = action.payload;
        },

        setSelectedLineChartsPrzekroje: (state, action) => {
            localStorage.setItem("s_lCHP", JSON.stringify(action.payload));
            state.selectedLineChartsPrzekroje = action.payload;
        },
        setDefaultLineChartsPrzekroje: (state, action) => {
            localStorage.setItem("s_dlCHP", JSON.stringify(action.payload));
            state.defaultLineChartsPrzekroje = action.payload;
        },

        setSelectedMapsPrzekroje: (state, action) => {
            localStorage.setItem("s_MP", JSON.stringify(action.payload));
            state.selectedMapsPrzekroje = action.payload;
        },
        setDefaultMapsPrzekroje: (state, action) => {
            localStorage.setItem("s_dMP", JSON.stringify(action.payload));
            state.defaultMapsPrzekroje = action.payload;
        },
    },
});

export const {
    setSelectedTablePrzekroje,
    setDefaultTablePrzekroje,

    setSelectedChartsPrzekroje,
    setDefaultChartsPrzekroje,

    setSelectedLineChartsPrzekroje,
    setDefaultLineChartsPrzekroje,

    setSelectedMapsPrzekroje,
    setDefaultMapsPrzekroje,
} = AdvDimensionsSlice.actions;

export const selectTablePrzekroje = (state) => state.AdvDimensions.selectedTablePrzekroje;
export const selectDefaultTablePrzekroje = (state) => state.AdvDimensions.defaultTablePrzekroje;

export const selectChartsPrzekroje = (state) => state.AdvDimensions.selectedChartsPrzekroje;
export const selectDefaultChartsPrzekroje = (state) => state.AdvDimensions.defaultChartsPrzekroje;

export const selectLineChartsPrzekroje = (state) => state.AdvDimensions.selectedLineChartsPrzekroje;
export const selectDefaultLineChartsPrzekroje = (state) => state.AdvDimensions.defaultLineChartsPrzekroje;

export const selectMapsPrzekroje = (state) => state.AdvDimensions.selectedMapsPrzekroje;
export const selectDefaultMapsPrzekroje = (state) => state.AdvDimensions.defaultMapsPrzekroje;

export default AdvDimensionsSlice.reducer;
