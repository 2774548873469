import {createSlice} from '@reduxjs/toolkit';

/**
 * Search Slice
 *
 * exports reducer ***SearchSlice.reducer***
 *
 * ----
 *
 * @alias SearchSlice
 *
 * @example
 *
 * import {useSelector, useDispatch} from 'react-redux';
 * import {selectSelectedSearchItem, setSelectedSearchItem} from 'path/to/appStore/Search';
 *
 * ...
 *
 * const MyComponent = () => {
 *     const dispatch = useDispatch();
 *
 *     //select
 *     const selectedSearchItem = useSelector(selectSelectedSearchItem);
 *     // - or -
 *     const selectedSearchItem = useSelector(state => state.search.selectedSearchItem)
 *
 *     //set
 *     dispatch(setSelectedSearchItem(Object));
 *
 *     ...
 *
 *     return(
 *         <pre>{JSON.stringify(selectedSearchItem, null, 4)}</pre>
 *     )
 *
 * }
 * export default MyComponent
 *
 * @param {Object} initialState
 * @param {Object} initialState.selectedSearchItem *Selected (current object)*
 *
 * stores selected search object got on home page. Looks for localStorage item `selectedSearchItem`
 * and if finds sets to store
 *
 * @param {} selectors
 * @param {function} selectors.selectSelectedSearchItem returns object (current)
 *
 * @param {Object} reducers
 * @param {function} reducers.setSelectedSearchItem Redux action to set `selectedSearchItem`
 *
 * stores selected search object in localStorage item `selectedSearchItem`
 *
 * @module SearchSlice
 * @category . Redux store
 * */

export const SearchSlice = createSlice({
    name: 'search',
    initialState: {
        selectedSearchItem: localStorage.getItem('selectedSearchItem')
            ? JSON.parse(localStorage.getItem('selectedSearchItem'))
            : null
    },
    reducers: {
        setSelectedSearchItem: (state, action) => {
            localStorage.setItem('selectedSearchItem', JSON.stringify(action.payload))
            state.selectedSearchItem = action.payload;
        },
    },
});

export const {setSelectedSearchItem} = SearchSlice.actions;

export const selectSelectedSearchItem = state => state.search.selectedSearchItem;

export default SearchSlice.reducer;
