import React, { Suspense } from "react";

import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useSelector } from "react-redux";
import { getStoreVersion } from "./appStore/APP";
import { clear } from "./appStore/_idb";
import "./common/i18n";

const Routes = React.lazy(() => import("./routes"));
const PageShareDataProducer = React.lazy(() => import("./common/PageShareDataProducer"));

function App() {
    const { enableLinkTracking } = useMatomo();
    enableLinkTracking();

    const storeVersion = useSelector(getStoreVersion);
    const storeMajor = Number(storeVersion?.split(".")[0] || -1);
    const storeMiddle = Number(storeVersion?.split(".")[1] || -1);
    const storageVersion = localStorage.getItem("sver.", "APP");
    const xat = localStorage.getItem("x-a-t", "APP");
    const storageMajor = Number(storageVersion?.split(".")[0] || -1);
    const storageMiddle = Number(storageVersion?.split(".")[1] || -1);

    if (storeMajor !== storageMajor) {
        console.log("%c CLEAR ", "background:#F00;color:#FFF", {
            storeVersion,
            storeMajor,
            storeMiddle,
            storageMajor,
            storageMiddle,
            xat,
        });
        clear().then(() => {
            localStorage.clear();
            localStorage.setItem("sver.", storeVersion, "APP");
            localStorage.setItem("x-a-t", xat, "APP");
            localStorage.setItem("sver.", storeVersion);
            localStorage.setItem("x-a-t", xat);
            window.location.reload();
        });
    } else {
        localStorage.setItem("sver.", storeVersion, "APP");
        localStorage.setItem("x-a-t", xat, "APP");
        localStorage.setItem("sver.", storeVersion);
        localStorage.setItem("x-a-t", xat);
    }

    return (
        <Suspense fallback={<div>Ładowanie...</div>}>
            <Routes />
            <PageShareDataProducer/>
        </Suspense>
    );
}

export default App;
