import {createSlice} from '@reduxjs/toolkit';

export const ReportSlice = createSlice({
    name: 'report',
    initialState: {
        reportData: null,
        exporting: false
    },
    reducers: {
        setReportData: (state, action) => {
            state.reportData = action.payload;
        },
        setExporting: (state, action) => {
            state.exporting = action.payload;
        },
    },
});

export const {setReportData, setExporting} = ReportSlice.actions;

export const reportData = state => state.report.reportData;
export const isExporting = state => state.report.exporting;

export default ReportSlice.reducer;
