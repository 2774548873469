import { createSlice } from "@reduxjs/toolkit";

/**
 * AdvancedCommon Slice
 *
 * exports reducer ***AdvancedCommonSlice.reducer***
 *
 * ----
 *
 * @alias AdvancedCommonSlice
 *
 * export default MyComponent
 *
 * @module AdvancedCommonSlice
 * @category . Redux store
 * */

export const AdvancedCommonSlice = createSlice({
    name: "AdvancedCommon",
    initialState: {
        rightDrawerOpened: localStorage.getItem("RDO") ? !!localStorage.getItem("RDO") : false,
        rightDrawerWidth: 400,
        rightDrawerExpanded: localStorage.getItem("RDE") ? localStorage.getItem("RDE") : "tables",
        leftDrawerOpened: localStorage.getItem("LDO")
            ? localStorage.getItem("LDO").toString() === "false"
                ? false
                : true
            : true,
        leftDrawerWidth: 580,
        leftPanelChecks: false,
        shakeCard: false,
        selectionLimits: {
            JT: 999,
            WSK: 999,
            YEAR: 999,
            TOTAL: 2500,
        },
        currentSection: localStorage.getItem("cAdSe")
            ? // ? localStorage.getItem('cAdSe').toString().length
              Number(localStorage.getItem("cAdSe"))
            : // : 0
              0,
        showLoginDialog: false,
        showAccountDialog: false,
        authToken: localStorage.getItem("x-a-t")
            ? JSON.parse(localStorage.getItem("x-a-t"))
            : sessionStorage.getItem("x-a-t")
            ? JSON.parse(sessionStorage.getItem("x-a-t"))
            : null,
        authRememberMe: localStorage.getItem("x-a-t-r") ? JSON.parse(localStorage.getItem("x-a-t-r")) : true,
        authMessage: localStorage.getItem("x-a-t-m") ? JSON.parse(localStorage.getItem("x-a-t-m")) : null,
        renderAsSVG: false,
        isExporting: false,
        isSyncingRepo: false,
        syncRepo: false,
        expanded: localStorage.getItem("lpE") ? JSON.parse(localStorage.getItem("lpE")) :"panel1",
    },
    reducers: {
        setRightDrawerOpened: (state, action) => {
            localStorage.setItem("RDO", action.payload ? action.payload : "");
            state.rightDrawerOpened = action.payload;
        },
        setRightDrawerWidth: (state, action) => {
            state.rightDrawerWidth = action.payload;
        },
        setRightDrawerExpanded: (state, action) => {
            localStorage.setItem("RDE", action.payload);
            state.rightDrawerExpanded = action.payload;
        },
        setLeftDrawerOpened: (state, action) => {
            localStorage.setItem("LDO", action.payload);
            state.leftDrawerOpened = action.payload;
        },
        setLeftDrawerWidth: (state, action) => {
            state.leftDrawerWidth = action.payload;
        },
        setLeftPanelChecks: (state, action) => {
            state.leftPanelChecks = action.payload;
        },
        setShakeCard: (state, action) => {
            state.shakeCard = action.payload;
        },
        setCurrentSection: (state, action) => {
            localStorage.setItem("cAdSe", action.payload ? action.payload : 0);
            state.currentSection = action.payload;
        },
        setShowLoginDialog: (state, action) => {
            state.showLoginDialog = action.payload;
        },
        setShowAccountDialog: (state, action) => {
            state.showAccountDialog = action.payload;
        },
        setAuthRememberMe: (state, action) => {
            localStorage.setItem("x-a-t-r", JSON.stringify(action.payload));
            state.authRememberMe = action.payload;
        },
        setAuthToken: (state, action) => {
            state.authRememberMe
                ? localStorage.setItem("x-a-t", JSON.stringify(action.payload))
                : sessionStorage.setItem("x-a-t", JSON.stringify(action.payload));
            state.authToken = action.payload;
        },
        setAuthMessage: (state, action) => {
            localStorage.setItem("x-a-t-m", JSON.stringify(action.payload));
            state.authMessage = action.payload;
        },
        setRenderAsSVG: (state, action) => {
            state.renderAsSVG = action.payload;
        },
        setIsExporting: (state, action) => {
            state.isExporting = action.payload;
        },
        setSyncRepo: (state, action) => {
            state.syncRepo = action.payload;
        },
        setIsSyncingRepo: (state, action) => {
            state.isSyncingRepo = action.payload;
        },
        setExpanded: (state, action) => {
            localStorage.setItem("lpE", JSON.stringify(action.payload));
            state.expanded = action.payload;
        },

    },
});

export const {
    setLeftPanelChecks,
    setLeftDrawerOpened,
    setLeftDrawerWidth,
    setRightDrawerOpened,
    setRightDrawerWidth,
    setRightDrawerExpanded,
    setShakeCard,
    setCurrentSection,
    setShowLoginDialog,
    setShowAccountDialog,
    setAuthRememberMe,
    setAuthToken,
    setAuthMessage,
    setRenderAsSVG,
    setIsExporting,
    setSyncRepo,
    setIsSyncingRepo,
    setExpanded
} = AdvancedCommonSlice.actions;

export const selectRightDrawerOpened = (state) => state.AdvancedCommon.rightDrawerOpened;
export const selectRightDrawerWidth = (state) => state.AdvancedCommon.rightDrawerWidth;
export const selectRightDrawerExpanded = (state) => state.AdvancedCommon.rightDrawerExpanded;
export const selectLeftDrawerOpened = (state) => state.AdvancedCommon.leftDrawerOpened;
export const selectLeftDrawerWidth = (state) => state.AdvancedCommon.leftDrawerWidth;
export const selectLeftPanelChecks = (state) => state.AdvancedCommon.leftPanelChecks;
export const selectShakeCard = (state) => state.AdvancedCommon.shakeCard;
export const selectSelectionLimits = (state) => state.AdvancedCommon.selectionLimits;
export const selectCurrentSection = (state) => state.AdvancedCommon.currentSection;
export const selectShowLoginDialog = (state) => state.AdvancedCommon.showLoginDialog;
export const selectShowAccountDialog = (state) => state.AdvancedCommon.showAccountDialog;
export const getAuthRememberMe = (state) => state.AdvancedCommon.authRememberMe;
export const selectAuthToken = (state) => state.AdvancedCommon.authToken;
export const selectAuthMessage = (state) => state.AdvancedCommon.authMessage;
export const getRenderAsSVG = (state) => state.AdvancedCommon.renderAsSVG;
export const getIsExporting = (state) => state.AdvancedCommon.isExporting;
export const getIsSyncingRepo = (state) => state.AdvancedCommon.isSyncingRepo;
export const getSyncRepo = (state) => state.AdvancedCommon.syncRepo;
export const getExpanded = (state) => state.AdvancedCommon.expanded;

export default AdvancedCommonSlice.reducer;
