import { configureStore } from "@reduxjs/toolkit";

import AreasReducer from "./Areas";
import IndicatorsRedicer from "./Indicators";
import SearchReducer from "./Search";
import YearsReducer from "./Years";
import ReportReducer from "./Report";
import BasicAccordionReducer from "./BasicAccordion";
import BasicCommonReducer from "./BasicCommon";
import AdvancedJTReducer from "./advTerritorialUnits";
import AdvIndicatorsReducer from "./advIndicators";
import AdvancedCommonReducer from "./AdvancedCommon";
import AdvancedTableReducer from "./advTable";
import AdvRepozytoriumRedicer from "./advRepozytorium";

import ChartsReducer from "./Charts";
import AdvancedChartsReducer from "./advCharts";

import AdvMapReducer from "./advMap";
import AdvMapDialogsReducer from "./advMapDialogs";
import APPReducer from "./APP";

import AdvReportReducer from "./advReport";

import AdvancedApiDataReducer from "./advApiData";

import AdvDimensionsReducer from "./advDimensions";

import { processJWT } from "./_middleware";

/** Application store
 *
 * @version 2.1.0
 *
 * @param {function} configureStore
 *
 * @property {Object} reducer
 * @property {AreasReducer} reducer.areas import reducer [***AreasSlice.reducer***](./module-AreasSlice.html)
 * @property {IndicatorsRedicer} reducer.indicators import reducer [***IndicatorsSlice.reducer***](./module-IndicatorsSlice.html)
 * @property {SearchReducer} reducer.search import reducer [***SearchSlice.reducer***](./module-SearchSlice.html)
 * @property {YearsReducer} reducer.years import reducer [***YearsSlice.reducer***](./module-YearsSlice.html)
 * @property {ReportReducer} reducer.report import reducer [***ReportSlice.reducer***](./module-ReportSlice.html)
 * @property {AdvancedJTReducer} reducer.territorialUnits import reducer [***AdvancedJTSlice.reducer***](./module-AdvancedJTSlice.html)
 * @property {AdvIndicatorsReducer} reducer.advIndicators import reducer [***AdvIndicatorsSlice.reducer***](./module-AdvIndicatorsSlice.html)
 * @property {AdvancedCommonReducer} reducer.AdvancedCommon import reducer [***AdvancedCommonSlice.reducer***](./module-AdvancedCommonSlice.html)
 * @property {AdvancedTableReducer} reducer.AdvancedTable import reducer [***AdvancedTableSlice.reducer***](./module-AdvancedTableSlice.html)
 * @property {AdvancedChartsReducer} reducer.AdvancedCharts import reducer [***AdvancedChartsSlice.reducer***](./module-AdvancedChartsSlice.html)
 * @property {AdvMapReducer} reducer.AdvMap import reducer [***AdvMapSlice.reducer***](./module-AdvMapSlice.html)
 * @property {APPReducer} reducer.APP import reducer [***APPSlice.reducer***](./module-APPSlice.html)
 *
 * @module _store
 * @category . Redux store
 */

export default configureStore({
    reducer: {
        areas: AreasReducer,
        indicators: IndicatorsRedicer,
        search: SearchReducer,
        years: YearsReducer,
        report: ReportReducer,
        BasicAccordion: BasicAccordionReducer,
        BasicCommon: BasicCommonReducer,
        AdvancedApiData: AdvancedApiDataReducer,
        AdvancedJT: AdvancedJTReducer,
        AdvIndicators: AdvIndicatorsReducer,
        AdvDimensions: AdvDimensionsReducer,
        AdvancedCommon: AdvancedCommonReducer,
        AdvancedTable: AdvancedTableReducer,
        charts: ChartsReducer,
        AdvancedCharts: AdvancedChartsReducer,
        AdvMap: AdvMapReducer,
        AdvMapDialogs: AdvMapDialogsReducer,
        APP: APPReducer,
        AdvRepozytorium: AdvRepozytoriumRedicer,
        AdvReport: AdvReportReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }).concat(processJWT),
});
