import { createSlice } from "@reduxjs/toolkit";
import { get, set } from "./_idb";

export const AdvRepozytoriumSlice = createSlice({
    name: "AdvRepozytorium",
    initialState: {
        // Tables: localStorage.getItem('rTs', 'APP')
        //     ? JSON.parse(localStorage.getItem('rTs', 'APP'))
        //     : [],
        Tables: null,
        // Charts: localStorage.getItem("rCs", "APP") ? JSON.parse(localStorage.getItem("rCs", "APP")) : [],
        Charts: null,
        // Maps: localStorage.getItem("rMs", "APP") ? JSON.parse(localStorage.getItem("rMs", "APP")) : [],
        Maps: null,
        // Reports: localStorage.getItem("rRs") ? JSON.parse(localStorage.getItem("rRs")) : [],
        Reports: null,
        Groups: localStorage.getItem("rGs") ? JSON.parse(localStorage.getItem("rGs")) : [],
        isLoadingRepo: false,
        validDate: "10/11/2023 05:59",
        // validDate: "02/22/2023 14:59",
    },
    reducers: {
        setTables: (state, action) => {
            // localStorage.setItem('rTs', JSON.stringify(action.payload))
            set("rTs", JSON.stringify(action.payload));
            state.Tables = action.payload;
        },
        setCharts: (state, action) => {
            // localStorage.setItem('rCs', JSON.stringify(action.payload))
            set("rCs", JSON.stringify(action.payload));
            state.Charts = action.payload;
        },
        setMaps: (state, action) => {
            // localStorage.setItem('rMs', JSON.stringify(action.payload))
            set("rMs", JSON.stringify(action.payload));
            state.Maps = action.payload;
        },
        setReports: (state, action) => {
            // localStorage.setItem('rRs', JSON.stringify(action.payload))
            set("rRs", JSON.stringify(action.payload));
            state.Reports = action.payload;
        },
        setGroups: (state, action) => {
            localStorage.setItem("rGs", JSON.stringify(action.payload));
            // set('rGs', JSON.stringify(action.payload))
            state.Groups = action.payload;
        },
        setIsLoadingRepo: (state, action) => {
            state.isLoadingRepo = action.payload;
        },
    },
});

export const fetchRepoFromIndexDB = () => (dispatch, getState) => {
    // console.log("%c          fetchRepoFromIndexDB             ", "background:#A00");
    get("rTs").then((val) => {
        // console.log("%c - rTs ", "background:#F00", val);
        val = val ? JSON.parse(val) : [];
        dispatch(setTables(val));
    });

    get("rCs").then((val) => {
        val = val ? JSON.parse(val) : [];
        dispatch(setCharts(val));
    });

    get("rMs").then((val) => {
        val = val ? JSON.parse(val) : [];
        dispatch(setMaps(val));
    });

    get("rRs").then((val) => {
        val = val ? JSON.parse(val) : [];
        dispatch(setReports(val));
    });
};

export const { setTables, setCharts, setMaps, setReports, setGroups, setIsLoadingRepo } = AdvRepozytoriumSlice.actions;

export const selectTables = (state) => state.AdvRepozytorium.Tables;
export const selectCharts = (state) => state.AdvRepozytorium.Charts;
export const selectMaps = (state) => state.AdvRepozytorium.Maps;
export const selectReports = (state) => state.AdvRepozytorium.Reports;
export const selectGroups = (state) => state.AdvRepozytorium.Groups;
export const getValidDate = (state) => state.AdvRepozytorium.validDate;

export const selectIsLoadingRepo = (state) => state.AdvRepozytorium.isLoadingRepo;

export default AdvRepozytoriumSlice.reducer;
