import { createSlice } from "@reduxjs/toolkit";

import packageJSON from "../../package.json";

/**
 * APP Slice
 *
 * exports reducer ***APPSlice.reducer***
 *
 * ----
 *
 * @alias APPSlice
 *
 * export default MyComponent
 *
 * @module APPSlice
 * @category . Redux store
 * */

export const APPSlice = createSlice({
    name: "APP",
    initialState: {
        appError: [],
        welcome: sessionStorage.getItem("welcome")
            ? sessionStorage.getItem("welcome").toString() === "false"
                ? false
                : true
            : true,
        storeVersion: packageJSON.version, //"1.0.0",
        sharePageData: null,// { url: "", title: "", subtitles: [], body: "" },
    },
    reducers: {
        setAppError: (state, action) => {
            state.appError = action.payload;
        },
        setWelcome: (state, action) => {
            sessionStorage.setItem("welcome", action.payload);
            state.welcome = action.payload;
        },
        setStoreVersion: (state, action) => {
            sessionStorage.setItem("sver.", action.payload);
            state.storeVersion = action.payload;
        },
        setSharePageData: (state, action) => {
            state.sharePageData = action.payload;
        },
    },
});

export const { setAppError, setWelcome, setStoreVersion, setSharePageData } = APPSlice.actions;

export const selectAppError = (state) => state.APP.appError;
export const selectWelcome = (state) => state.APP.welcome;
export const getStoreVersion = (state) => state.APP.storeVersion;
export const getSharePageData = (state) => state.APP.sharePageData;

export default APPSlice.reducer;
