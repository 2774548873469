import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { LOCAL_TEST, BASE_URL } from "../API/apiConfig";
import { setAppError } from "./APP";

/**
 * Years Slice
 *
 * exports reducer ***YearsSlice.reducer***
 *
 * ----
 *
 * @alias YearsSlice
 *
 * @example
 *
 * import {useSelector, useDispatch} from 'react-redux';
 * import {selectYears, setDefaultYear} from 'path/to/appStore/Years';
 *
 * ...
 *
 * const MyComponent = () => {
 *     const dispatch = useDispatch();
 *
 *     //select
 *     const years = useSelector(selectYears);
 *     // - or -
 *     const years = useSelector(state => state.years.years)
 *
 *     //set
 *     dispatch(setDefaultYear(Object));
 *
 *     ...
 *
 *     return(
 *         <pre>{JSON.stringify(years, null, 4)}</pre>
 *     )
 *
 * }
 * export default MyComponent
 *
 * @param {Object} initialState
 * @param {Object[]} initialState.years *array of objects*
 * @param {Object} initialState.defaultYear *Selected (current object)*
 *
 * @param {} selectors
 * @param {function} selectors.selectYears returns array of objects
 * @param {function} selectors.selectDefaultYear returns object
 *
 * @param {Object} reducers
 * @param {function} reducers.setYears Redux action to set `years`
 * @param {function} reducers.setDefaultYear Redux action to set `defaultYear`
 *
 * @param {function} fetchYears Redux action creator to fetch data from api ***`${BASE_URL}Slowniki/GetLata`***,

 and set `years` and `defaultYear`

 *
 *
 * @module YearsSlice
 * @category . Redux store
 * */

export const YearsSlice = createSlice({
    name: "years",
    initialState: {
        years: [],
        defaultYear: localStorage.getItem("dY")
            ? JSON.parse(localStorage.getItem("dY"))
            : { id_daty: "20191231", rok: 2019 },
        selectedYears: localStorage.getItem("aSY") ? JSON.parse(localStorage.getItem("aSY")) : [],
        resetYear: localStorage.getItem("rY") ? JSON.parse(localStorage.getItem("rY")) : true,
    },
    reducers: {
        setYears: (state, action) => {
            state.years = action.payload;
        },
        setDefaultYear: (state, action) => {
            localStorage.setItem("dY", JSON.stringify(action.payload));
            state.defaultYear = action.payload;
        },
        setSelectedYears: (state, action) => {
            localStorage.setItem("aSY", JSON.stringify(action.payload));
            state.selectedYears = action.payload;
        },
        setResetYear: (state, action) => {
            localStorage.setItem("rY", JSON.stringify(action.payload));
            state.selectedYears = action.payload;
        },
    },
});

export const fetchYears = () => (dispatch, getState) => {
    const apiURL = LOCAL_TEST ? "./devData/GetLata.json" : `${BASE_URL}Slowniki/GetLata`;
    axios
        .get(apiURL)
        .then((response) => {
            dispatch(setYears(response.data.sort((a, b) => b.rok - a.rok)));

            const dY = localStorage.getItem("dY")
                ? JSON.stringify(localStorage.getItem("dY"))
                : { id_daty: "20191231", rok: 2019 };

            dispatch(setDefaultYear(response.data.find((x) => x.id_daty.toString() === dY.id_daty.toString())));

            dispatch(setAppError(getState().APP.appError.filter((e) => e.type !== "getYears")));
        })
        .catch((error) => {
            dispatch(
                setAppError([
                    ...getState().APP.appError,
                    { type: "getYears", message: `Can't get YEARs: ${error.message}` },
                ])
            );
        })
        .finally(() => {});
};
export const { setYears, setDefaultYear, setSelectedYears, setResetYear } = YearsSlice.actions;

export const selectYears = (state) => state.years.years;
export const selectDefaultYear = (state) => state.years.defaultYear;
export const selectSelectedYears = (state) => state.years.selectedYears;
export const getResetYear = (state) => state.years.resetYear;

export default YearsSlice.reducer;
